import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import { CompletionStatusProps } from '../types/Calendar/ICalendarIndex';
import { theme } from '../../assets/siteLineTheme';
import { StoreLocationInformationProps } from '../../models/StoreLocationInformation';
import React from 'react';
import {ExpandMore} from "@mui/icons-material";
import {IconButton} from "../Buttons/IconButton";
import {IconEnum} from "../Chip/chipUtils/ChipEnums";
import {analyticsEventConstants, sendAnalyticsEvent} from "../../analytics/analytics";
import {DialogModalSwitchEnum} from "../Modal/DialogModalSwitch";
import DistrictStoreNumberList from "./DistrictStoreNumberList";
import {storeNumberAccordionStyles} from "./storeNumberAccordionStyles";

const useStyles = (isPrintView?: boolean) => {
    return {
        accordionRoot: {
            paddingLeft: '1em',
        },
        accordionDetails: {
            paddingBottom: '.5em',
            paddingTop: '0em',

            display: 'flex',
            flexDirection: 'column',
        },
        accordionDetailsList: {
            padding: '0px',
            maxHeight: isPrintView ? 'fit-content' : '400px',
            overflowY: isPrintView ? 'hidden' : 'scroll',
        },
        accordionListItem: {
            fontSize: '.85em',
            paddingBottom: '0em',
            paddingTop: '0em',
        },
        accordionDetailsTitle: {
            fontSize: '.85em',
        },
        accordionSummaryText: {
            margin: 'auto 0px',
        },
        accordionSummaryRoot: {
            height: 'fit-content',
        },
        completionStatusTextSpan: {
            color: theme.colorPalette.completionStatus.completed,
            fontStyle: 'italic',
        },
        overDueStatusRestSpan: {
            color: theme.colorPalette.completionStatus.overdue,
            fontStyle: 'italic',
        },
        scheduledDateTextSpan: {
            color: theme.colorPalette.completionStatus.scheduledDate,
            fontStyle: 'italic',
        },
        commentIconAndCountContainer: {
            display: 'flex',
        },
        commentCountText: {
            fontSize: '.85em',
            marginLeft: '.3em',
            color: theme.colorPalette.backgroundDark,
        },
    }
}

export type RegionStoreNumberAccordionProps = {
    isPrintView?: boolean;
    completionStatuses?: CompletionStatusProps[];
    storeNumbersAndNamesForRegion: StoreLocationInformationProps[];
    setCommentsModalContent: Function;
    setBaseModalContent: Function;
    taskId: string;
};

const RegionStoreNumberAccordion:React.FC<RegionStoreNumberAccordionProps> = (props) => {
    const { completionStatuses, storeNumbersAndNamesForRegion, isPrintView
        , setCommentsModalContent, setBaseModalContent, taskId} = props;

    const storeNumberAccordionClasses = storeNumberAccordionStyles();

    const classes = isPrintView
        ? useStyles(isPrintView)
        : useStyles();

    const districtBucket = storeNumbersAndNamesForRegion.reduce(
        (acc: any, location: StoreLocationInformationProps) => {
            acc[location.district_number] = acc[location.district_number]
                ? [...acc[location.district_number], location]
                : [location];
            return acc;
        },
        {}
    );

    return (
        <>
        {Object.keys(districtBucket).map((districtNumber) => {
            return (
                <Accordion key={districtNumber} sx={classes.accordionRoot}>
                    <AccordionSummary
                        sx={classes.accordionSummaryRoot}
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div
                            style={
                                storeNumberAccordionClasses.districtAccordionHeaderContainer
                            }
                        >
                            <Typography sx={classes.accordionSummaryText}>
                                {`District ${districtNumber} - ${districtBucket[districtNumber][0].district_name}`}
                            </Typography>
                            {districtBucket[districtNumber][0].comment_count_by_district !==
                                undefined &&
                                districtBucket[districtNumber][0].comment_count_by_district >
                                0 && (
                                    <div style={classes.commentIconAndCountContainer}>
                                        <IconButton
                                            icon={IconEnum.ViewComments}
                                            label={'Open comments modal'}
                                            onClick={() => {
                                                sendAnalyticsEvent(
                                                    analyticsEventConstants.VIEW_COMMENTS_FROM_TERRITORY_DROPDOWN
                                                );
                                                setCommentsModalContent({
                                                    task_id: taskId,
                                                    location_type: 'district',
                                                    location_number:
                                                    districtBucket[districtNumber][0].district_number,
                                                    location_name:
                                                    districtBucket[districtNumber][0].district_name,
                                                });
                                                setBaseModalContent({
                                                    isActive: true,
                                                    dialogModalSwitchEnum:
                                                    DialogModalSwitchEnum.StoreComment,
                                                    width: window.innerWidth > 420 ? 0.25 : 0.8,
                                                    height: window.innerWidth > 420 ? 0.45 : 0.85,
                                                });
                                            }}
                                        />
                                        <Typography sx={classes.commentCountText}>
                                            {`${
                                                districtBucket[districtNumber][0]
                                                    .comment_count_by_district
                                                    ? `(${districtBucket[districtNumber][0].comment_count_by_district})`
                                                    : ''
                                            }`}
                                        </Typography>
                                    </div>
                                )}
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <DistrictStoreNumberList
                            isPrintView={isPrintView}
                            completionStatuses={completionStatuses}
                            storeNumbersAndNamesForDistrict={districtBucket[districtNumber]}
                        />
                    </AccordionDetails>
                </Accordion>
            );
        })}
        </>
    );
};

export default RegionStoreNumberAccordion;
