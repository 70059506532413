import React from 'react';
import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import { recentlyViewedBoxContentStyles } from './recentlyViewedBoxContentStyles';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';
import { setMerchRecentlyViewed } from '../../../api/apiDigitalMerchandising';

export type RecentlyViewedBoxContentParams = {
  contentData: NavigationObj | CalendarEvent | any;
  onClick: Function | undefined;
  boxHeight: string | undefined;
  callBack: Function | undefined;
};

const RecentlyViewedBoxContent: React.FC<RecentlyViewedBoxContentParams> = (
  props
) => {
  const { contentData, callBack } = props;
  const recentlyViewedBoxContentClasses = recentlyViewedBoxContentStyles();

  const hId = contentData.url && contentData.url.split('/')[4];
  const url = `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=${hId}`;

  const script = document.createElement('script');
  /* todo - figure out why there is an EOL error */
  script.src = url;
  // script.src = `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=529376201`;
  script.async = true;

  document.head.appendChild(script);
  document.head.removeChild(script);

  const setAsRecentlyViewed = async () => {
    await setMerchRecentlyViewed(contentData.code);
    if (callBack !== undefined) {
      callBack();
    }
    sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_FLIPPINGBOOK);
  };
  return (
    <div
      className={'recentlyViewedBoxContentContainer'}
      style={recentlyViewedBoxContentClasses.recentlyViewedBoxContentContainer}
    >
      <div
        className={'recentlyViewedBoxContentBookContainer'}
        style={
          recentlyViewedBoxContentClasses.recentlyViewedBoxContentBookContainer
        }
      >
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          aria-label={`NavigationTo${contentData.url}`}
          onClick={() => {
            setAsRecentlyViewed();
          }}
        >
          <a
            href={`${contentData.url}`}
            className="fbo-embed"
            data-fbo-id="09eaeaffad"
            data-fbo-ratio={window.innerWidth > 420 ? '0' : '3:2'}
            data-fbo-lightbox="yes"
            data-fbo-width={window.innerWidth > 420 ? '0' : '100%'}
            data-fbo-height={window.innerWidth > 420 ? '0' : 'auto'}
            data-fbo-version="1"
            style={{ display: 'flex', maxWidth: '100%', marginRight: '1em' }}
          />
        </div>
        <div
          style={
            recentlyViewedBoxContentClasses.recentlyViewedBoxContentTextContainer
          }
        >
          {decodeURIComponent(contentData.title)}
        </div>
      </div>
    </div>
  );
};

export default RecentlyViewedBoxContent;
