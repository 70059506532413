import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { env } from './envConfig';
import ReactGA from 'react-ga4'

const GA_ID = env.REACT_APP_GA_TRACKING_ID

ReactGA.initialize(GA_ID, {
    gtagOptions: { send_page_view: false },
});

ReactGA.gtag("config", GA_ID, {
    send_page_view: false,
    allow_google_signals: false,
    user_engagement: false,
});

ReactGA.set({ 'allow_google_signals': false });

ReactGA.set({
    'custom_map':
        {
            'dimension1': 'store_number',
            'dimension2': 'territory_number',
            'dimension3': 'district_number',
            'dimension4': 'job_title',
            'dimension5': 'employee_id',
            'dimension6': 'siteline_group',
            'dimension7': 'attachment_type',
            'metric1': 'avg_page_load_time',
            'dimension8': 'region_number'
        }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
