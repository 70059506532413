import React from 'react';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { updateTaskStoreCompletionStatus } from '../../api/apiV1Task';
import { TaskStoreCompletionStatus } from '../../models/TaskStoreCompletionStatus';
import { theme } from '../../assets/siteLineTheme';
import isDialogModalOpenStore from '../../stores/isDialogModalOpenStore';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../analytics/analytics';

export type CompletionInfoProps = {
  completion_status: string;
  date_last_modified: string;
  last_modified_by: { name: string; empID: string };
  store_number: string;
  task_id: string;
};

export type DialogModalProps = {
  resetToDefault: Function;
  completionInfo: CompletionInfoProps;
};

const useStyles = () => {
    return {
      actionButtonBox: {
        display: 'flex',
        padding: '.5em',
        justifyContent: 'center',
        marginBottom: '1em',
      },
      dialogActionButton: {
        margin: '0 1em',
        padding: '.5em 1em',
        borderRadius: '5px',
      },
      cancelButton: {
        color: theme.colorPalette.plainWhite,
        backgroundColor: theme.colorPalette.zoidberg,
        '&:hover': {
          backgroundColor: 'rgba(242, 111, 92, 0.85)',
        },
      },
      confirmButton: {
        color: theme.colorPalette.plainWhite,
        backgroundColor: theme.colorPalette.plainBlack,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.85)', // zoidberg with .85 opacity used on the hover
        },
      },
    }
  }

const DialogModal: React.FC<DialogModalProps> = (props) => {
  const { isDialogModalOpen, toggleIsDialogModalOpen } = isDialogModalOpenStore(
    (state: {
      isDialogModalOpen: boolean;
      toggleIsDialogModalOpen: Function;
    }) => ({
      isDialogModalOpen: state.isDialogModalOpen,
      toggleIsDialogModalOpen: state.toggleIsDialogModalOpen,
    })
  );

  const { completionInfo, resetToDefault } = props;
  const completionDate = new Date(completionInfo.date_last_modified);
  const classes = useStyles();

  const messageString = `This task was marked complete by ${
    completionInfo.last_modified_by.name
  } on ${format(completionDate, 'yyyy-MM-dd').toString()} at ${format(
    completionDate,
    'h:m a'
  ).toString()}.`;
  const confirmationMessage = 'Are you sure you want to re-open this task?';

  const handleClose = () => {
    toggleIsDialogModalOpen();
  };

  const handleConfirm = async () => {
    await updateTaskStoreCompletionStatus({
      id: completionInfo.task_id,
      store_number: completionInfo.store_number,
      completion_status: TaskStoreCompletionStatus.Incomplete,
    });
    await sendAnalyticsEvent(analyticsEventConstants.REOPEN_COMPLETED_TASK);
    toggleIsDialogModalOpen();
    resetToDefault();
  };

  return (
    <Dialog open={isDialogModalOpen} keepMounted onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{messageString}</DialogContentText>
        <DialogContentText>{confirmationMessage}</DialogContentText>
      </DialogContent>
      <Box sx={classes.actionButtonBox}>
        <Button
          aria-label={'Cancel re-open task button'}
          onClick={handleClose}
          sx={{...classes.dialogActionButton, ...classes.cancelButton}}
        >
          CANCEL
        </Button>
        <Button
          aria-label={'Confirm Reopening of Task Button'}
          onClick={handleConfirm}
          sx={{...classes.dialogActionButton, ...classes.confirmButton}}
        >
          REOPEN
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogModal;
