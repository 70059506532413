import { createContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { permissionSetter } from './permissionSetter';
import { menuSetter } from './menuSetter';
import { getStoreLocationInformation } from '../api/apiV1Task';
import { StoreLocationInformationProps } from '../models/StoreLocationInformation';
import { createOrUpdateEmployeeInfo } from '../api/apiEmployee';
import {useHistory} from "react-router-dom";

export const StateGenerator = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState({});
  const [userPermissions, setUserPermissions] = useState({});
  const [screens, setScreens] = useState({});
  const [storeLocationInformation, setStoreLocationInformation] = useState<
    StoreLocationInformationProps[]
  >([]);

  useEffect(() => {
    const getUserData = async () => {
      if (!authState?.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo({});
        setUserPermissions({});
        setScreens(menuSetter({}));
      } else {
        await oktaAuth?.getUser().then((info: any) => {
          localStorage.setItem(
            'userInfoObj',
            JSON.stringify({ ...info, updatedTimeStamp: Date.now() })
          );
          info.territory_number = info.territory;
          info.region_number = info.region;
          info.district_number = info.districtnumber;
          delete info.territory;
          delete info.region;
          delete info.districtnumber;
          setUserInfo({ ...info, updatedTimeStamp: Date.now() });
          setUserPermissions(permissionSetter(info));
          setScreens(menuSetter(info));
          fetchStoreLocationInformation();
          createOrUpdateEmployeeInfo();
        });
      }
    };
    getUserData();
  }, [authState, oktaAuth]);

  const history = useHistory();

  const fetchStoreLocationInformation = () => {
    getStoreLocationInformation()
      .then((value) => {
        setStoreLocationInformation(value);
      })
      .catch((error) => {
        console.error(error);
        history.replace('/error');
      });
  };

  return {
    userInfo,
    authState,
    userPermissions,
    screens,
    storeLocationInformation,
  };
};

const initialContextState = {
  userInfo: {},
  authState: {},
  userPermissions: {},
  screens: [],
  storeLocationInformation: [],
};

export const AppContext = createContext<any>(initialContextState);
