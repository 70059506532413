import React, { useContext, useEffect } from 'react';
import { useFetchTaskCompletionData } from '../hooks/useFetchTaskCompletionData';
import taskCompletionDashboardStore, {
  CompletionDashboardState,
  GroupByEnum,
} from '../stores/taskCompletionDashboardStore';
import CompletionDataHistogramController from '../components/Charts/ChartControllers/CompletionDataHistogramController';
import { taskCompletionDataStoreQueryString } from '../utils/taskCompletionDataStoreQueryString';
import TaskCompletionDataToolbar from '../components/Toolbar/TaskCompletionDataToolbar';
import { taskCompletionDashboardDataWrapperStyles } from './styles/taskCompletionDashboardDataWrapperStyles';
import {
  bottomAxisLabelBuilder,
  histogramTitleStringBuilder,
} from '../utils/stackedHistogramUtils';
import { downloadCsv } from '../utils/csvValidator';
import fireUseQueryStore from '../stores/fireUseQueryStore';
import { AppliedFiltersProps } from '../stores/models/AppliedFiltersProps';
import { TaskStoreCompletionStatus } from '../models/TaskStoreCompletionStatus';
import {
  appliedFilterQueryStringBuilder,
  FromViewType,
} from '../utils/appliedFilterQueryStringBuilder';
import { addDays } from 'date-fns';
import {
  getTaskCompletionList,
  getTaskCompletionListCsv, getTaskCompletionReportingListCsv,
} from '../api/apiTaskCompletionData';
import TaskCompletionDashboardDataList from '../components/Forms/TaskCompletionDashboard/TaskCompletionDashboardDataList';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../stores/userPreferenceStore';
import Papa from 'papaparse';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../analytics/analytics';
import { AppContext } from '../context/AppContext';
import { groups } from '../context/constants';
import { defaultTaskFiltersFilters } from 'src/utils/Data/defaultData';
import useFetchUserGroup from '../hooks/fetchUserGroup';
import snackbarPropsStore from "../stores/snackbarPropsStore";
import { useHistory } from 'react-router-dom';

export type CompletionDataObj = {
  task_type: string;
  completed: number;
  incompleted: number;
  overdue: number;
};

export type ChartSearchInformation = {
  chart_title: string;
  group_by: GroupByEnum;
};

export type ChartBarInformation = {
  title: string;
  value: string;
  completion_status: TaskStoreCompletionStatus;
};

export type AppStateObj = {
  storeLocationInformation: any;
  userInfo: any;
  defaultStoreNumber: { value: string; label: string } | undefined;
};

export type TaskCompletionDashboardDataWrapperState = {
  queryString: string;
  queryStringForList: string;
  taskCompletionList: Array<any>;
  chartSearchInformation: ChartSearchInformation;
  chartBarInformation: ChartBarInformation;
  appStateObj: AppStateObj;
};

const TaskCompletionDashboardDataWrapper: React.FC = () => {
  const { completionDashboardState, setCompletionDashboardState } =
    taskCompletionDashboardStore(
      (state: {
        completionDashboardState: CompletionDashboardState;
        setCompletionDashboardState: Function;
      }) => ({
        completionDashboardState: state.completionDashboardState,
        setCompletionDashboardState: state.setCompletionDashboardState,
      })
    );

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { fireUseQueryStoreProps, setFireUseQuery } = fireUseQueryStore(
    (state: { fireUseQueryStoreProps: any; setFireUseQuery: any }) => ({
      fireUseQueryStoreProps: state.fireUseQueryStoreProps,
      setFireUseQuery: state.setFireUseQuery,
    })
  );

  const { appState } = useContext<any>(AppContext);
  const { storeLocationInformation, userInfo } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);
  const setSnackbarProps = snackbarPropsStore.getState().setSnackbarProps;

  useEffect(() => {
    if (
      userOktaGroup !== undefined &&
      userOktaGroup !== '' &&
      storeLocationInformation !== undefined &&
      storeLocationInformation.length > 0
    ) {
      const fieldNameForNumber = 'location_number';
      const fieldNameForName = 'location_name';
      if (userOktaGroup === groups.MICHAEL) {
        const findStoreIndex = storeLocationInformation.findIndex(
          (store: string) => {
            return (
              store[fieldNameForNumber] === Number(userInfo.locationnumber)
            );
          }
        );
        if (findStoreIndex >= 0) {
          const name = `${storeLocationInformation[findStoreIndex][fieldNameForNumber]} - ${storeLocationInformation[findStoreIndex][fieldNameForName]}`;
          setCompletionDashboardState({
            ...completionDashboardState,
            storeNumber: userInfo.locationnumber,
          });
          setCompletionObj({
            ...completionObj,
            queryString: taskCompletionDataStoreQueryString({
              completionDashboardState: {
                ...completionDashboardState,
                storeNumber: userInfo.locationnumber,
              },
            }),
            appStateObj: {
              storeLocationInformation,
              userInfo,
              defaultStoreNumber: {
                value:
                  storeLocationInformation[findStoreIndex][fieldNameForNumber] +
                  '',
                label: name,
              },
            },
          });
          setFireUseQuery({ triggerTime: new Date(Date.now()) });
        }
      } else {
        setCompletionObj({
          ...completionObj,
          queryString: taskCompletionDataStoreQueryString({
            completionDashboardState,
          }),
          appStateObj: {
            storeLocationInformation,
            userInfo,
            defaultStoreNumber: undefined,
          },
        });
      }
    }
  }, [userOktaGroup, storeLocationInformation]);

  const [completionObj, setCompletionObj] =
    React.useState<TaskCompletionDashboardDataWrapperState>({
      queryString: taskCompletionDataStoreQueryString({
        completionDashboardState,
      }),
      queryStringForList: '',
      taskCompletionList: [],
      chartSearchInformation: {
        chart_title: histogramTitleStringBuilder(completionDashboardState),
        group_by: completionDashboardState.groupBy,
      },
      chartBarInformation: {
        title: '',
        value: '',
        completion_status: TaskStoreCompletionStatus.All,
      },
      appStateObj: {
        storeLocationInformation,
        userInfo,
        defaultStoreNumber: undefined,
      },
    });

  const history = useHistory();

  const { data, isLoading, error } = useFetchTaskCompletionData(
    completionObj.queryString,
    fireUseQueryStoreProps.triggerTime
  );

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      history.replace('/error');
    }
  }, [error]);

  const generateChartHandler = () => {
    setCompletionObj({
      ...completionObj,
      queryString: taskCompletionDataStoreQueryString({
        completionDashboardState,
      }),
      chartSearchInformation: {
        chart_title: histogramTitleStringBuilder(completionDashboardState),
        group_by: completionDashboardState.groupBy,
      },
      taskCompletionList: [],
    });
    setFireUseQuery({ triggerTime: new Date(Date.now()) });
  };

  const taskCompletionDashboardDataWrapperClasses =
    taskCompletionDashboardDataWrapperStyles({
      taskListLength: completionObj.taskCompletionList.length,
    });

  const handlerBarClick = async (
    groupByValue: string,
    completionStatus: string
  ) => {
    const taskTypes = { ...defaultTaskFiltersFilters.task_types };
    if (completionDashboardState.groupBy === GroupByEnum.TaskType) {
      taskTypes[groupByValue] = !taskTypes[groupByValue];
    } else {
      if (
        completionDashboardState.task_type &&
        completionDashboardState.task_type.length > 0
      ) {
        const taskTypeList =
          completionDashboardState.task_type.split('&task_type=');
        taskTypeList &&
          taskTypeList.forEach((task_type: string) => {
            if (task_type.length > 0) {
              taskTypes[task_type] = !taskTypes[task_type];
            }
          });
      }
    }

    const paramForList: AppliedFiltersProps = {
      appliedFilters: {
        task_types: taskTypes,
        task_status: undefined,
        completion_status: TaskStoreCompletionStatus[completionStatus],
        territory_number:
          completionDashboardState.groupBy === GroupByEnum.Territory
            ? Number(groupByValue)
            : completionDashboardState.territoryNumber
            ? Number(completionDashboardState.territoryNumber)
            : undefined,
        region_number:
          completionDashboardState.groupBy === GroupByEnum.Region
            ? Number(groupByValue)
            : completionDashboardState.regionNumber
            ? Number(completionDashboardState.regionNumber)
            : undefined,
        district_number:
          completionDashboardState.groupBy === GroupByEnum.District
            ? Number(groupByValue)
            : completionDashboardState.districtNumber
            ? Number(completionDashboardState.districtNumber)
            : undefined,
        store_number:
          completionDashboardState.groupBy === GroupByEnum.Store
            ? Number(groupByValue)
            : completionDashboardState.storeNumber
            ? Number(completionDashboardState.storeNumber)
            : undefined,
        min_selected_date: new Date(
          addDays(new Date(completionDashboardState.minQueryDate), 1)
            .toISOString()
            .split('T')[0]
        ),
        max_selected_date: new Date(
          addDays(new Date(completionDashboardState.maxQueryDate), 1)
            .toISOString()
            .split('T')[0]
        ),
        current_date: undefined,
        view_type: undefined,
      },
    };

    setCompletionObj({
      ...completionObj,
      chartBarInformation: {
        title: bottomAxisLabelBuilder(
          completionObj.chartSearchInformation.group_by
        ),
        value: groupByValue,
        completion_status: TaskStoreCompletionStatus[completionStatus],
      },
      queryStringForList: appliedFilterQueryStringBuilder(
        paramForList.appliedFilters,
        FromViewType.CompletionDashboard
      ),
      taskCompletionList: await getTaskCompletionList(
        appliedFilterQueryStringBuilder(
          paramForList.appliedFilters,
          FromViewType.CompletionDashboard
        )
      ),
    });
  };

  const handlerExportCsv = async (task_id: string, title: string) => {
    const allStoreCompletionList = await getTaskCompletionListCsv(
      task_id,
      completionObj.queryStringForList
    );

    if (allStoreCompletionList !== undefined) {
      const csv = Papa.unparse(allStoreCompletionList);
      downloadCsv(csv, title);
      sendAnalyticsEvent(
        analyticsEventConstants.COMPLETION_DASHBOARD_DATA_LIST
      );
    }
  };

  const handleDownloadReportingCSV = async () => {
    if(!completionDashboardState.districtNumber && !completionDashboardState.storeNumber) {
      window.alert('Please select at least district number.');
      return
    }

    setSnackbarProps({
      actionStatus: 'loading',
      isSnackbarVisible: true,
      correlationId: undefined,
    });

    const allStoreCompletionList = await getTaskCompletionReportingListCsv(
        completionObj.queryString
    );

    if (allStoreCompletionList !== undefined) {
      const title = `${completionDashboardState.storeNumber ? 'Store ' + completionDashboardState.storeNumber : 'District ' + completionDashboardState.districtNumber}_${completionDashboardState.minQueryDate}~${completionDashboardState.maxQueryDate}`;
      const csv = Papa.unparse(allStoreCompletionList);
      downloadCsv(csv, title);
      sendAnalyticsEvent(
          analyticsEventConstants.COMPLETION_DASHBOARD_REPORTING_DATA_LIST
      );
    }
    setSnackbarProps({
      actionStatus: 'loading',
      isSnackbarVisible: false,
      correlationId: undefined,
    });
  }

  return (
    <div
      aria-label={'TaskCompletionDashboardDataWrapper'}
      style={
        taskCompletionDashboardDataWrapperClasses.completionDashboardWrapperContainer
      }
    >
      <div
        style={
          taskCompletionDashboardDataWrapperClasses.completionDashboardWrapperFilterContainer
        }
      >
        {userOktaGroup !== '' &&
          storeLocationInformation &&
          ((completionObj.appStateObj.defaultStoreNumber &&
            userOktaGroup === groups.MICHAEL) ||
            userOktaGroup !== groups.MICHAEL) &&
          completionObj.appStateObj.storeLocationInformation.length > 0 && (
            <TaskCompletionDataToolbar
              generateChartHandler={generateChartHandler}
              handleDownloadReportingCSV={handleDownloadReportingCSV}
              appStateObj={completionObj.appStateObj}
            />
          )}
      </div>
      {!isLoading && data !== undefined && data.length !== 0 && (
        <div
          style={
            taskCompletionDashboardDataWrapperClasses.completionDashboardWrapperChartContainer
          }
        >
          <CompletionDataHistogramController
            data={data}
            titleString={completionObj.chartSearchInformation.chart_title}
            groupBy={completionObj.chartSearchInformation.group_by}
            onClickBar={handlerBarClick}
          />
        </div>
      )}
      {!isLoading && data !== undefined && data.length === 0 && (
        <div
          style={
            taskCompletionDashboardDataWrapperClasses.completionDashboardWrapperChartContainer
          }
        >
          {' '}
          NO DATA to show!
        </div>
      )}
      {completionObj.taskCompletionList.length !== 0 && (
        <div
          style={
            taskCompletionDashboardDataWrapperClasses.completionDataDashboardList
          }
        >
          <TaskCompletionDashboardDataList
            taskCompletionList={completionObj.taskCompletionList}
            rowNumberByPage={userPreference.viewRowNumber}
            colorMode={userPreference.colorMode}
            chartBarInformation={completionObj.chartBarInformation}
            handlerExportCsv={handlerExportCsv}
          />
        </div>
      )}
    </div>
  );
};

export default TaskCompletionDashboardDataWrapper;
