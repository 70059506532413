import { theme } from '../../assets/siteLineTheme';
import { AnalyticEventType, sendAnalyticsEvent } from '../../analytics/analytics';
import GenericButton from '../GenericButton/GenericButton';
import { ButtonEnum } from '../GenericButton/genericButttonUtils/ButtonEnum';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import React from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

type PrintButtonProps = {
  analyticsEvent: AnalyticEventType;
};

const PrintButton: React.FC<PrintButtonProps> = (props) => {
  const { analyticsEvent } = props;
  const handleClick = () => {
    sendAnalyticsEvent(analyticsEvent);
    window.print();
  };
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  // TODO: Remove the Original Button, Make sure test cases pass and make bacckground white while color mode is off
  return (
    <GenericButton
      variant={ButtonEnum.Filled}
      buttonTextColor={
        theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
      }
      iconEnum={IconEnum.Print}
      clickCallback={handleClick}
    >
      PRINT
    </GenericButton>
  );
};

export default PrintButton;
