import { TaskStoreCompletionStatus } from '../models/TaskStoreCompletionStatus';
import { formatStoreNumber } from '../components/Drawers/FilterDrawer/FilterDrawer';

/* eslint-disable */
export enum FromViewType {
  List = 'List',
  Calendar = 'Calendar',
  CompletionDashboard = 'CompletionDashboard',
  EasyPrint = 'EasyPrint',
}

export const appliedFilterQueryStringBuilder = (
  appliedFilters: any,
  fromView: FromViewType
) => {
  let queryString = '?';
  Object.keys(appliedFilters).forEach((key) => {
    switch (key) {
      case 'task_types':
        Object.keys(appliedFilters.task_types).forEach(
          (taskTypeKey: string) => {
            if (appliedFilters.task_types[taskTypeKey]) {
              queryString +=
                'task_type=' + encodeURIComponent(taskTypeKey) + '&';
            }
          }
        );
        break;
      case 'store_number':
        if (appliedFilters.store_number) {
          queryString +=
            'store_number=' +
            encodeURIComponent(formatStoreNumber(appliedFilters.store_number)) +
            '&';
        }
        break;
      case 'task_status':
        if (appliedFilters.task_status) {
          queryString +=
            'task_status=' +
            encodeURIComponent(appliedFilters.task_status) +
            '&';
        }
        break;
      case 'district_number':
        if (appliedFilters.district_number) {
          queryString +=
            'district_number=' +
            encodeURIComponent(appliedFilters.district_number) +
            '&';
        }
        break;
      case 'region_number':
        if (appliedFilters.region_number) {
          queryString +=
            'region_number=' +
            encodeURIComponent(appliedFilters.region_number) +
            '&';
        }
        break;
      case 'territory_number':
        if (appliedFilters.territory_number) {
          queryString +=
            'territory_number=' +
            encodeURIComponent(appliedFilters.territory_number) +
            '&';
        }
        break;
      case 'completion_status':
        if (
          appliedFilters.completion_status &&
          appliedFilters.completion_status === TaskStoreCompletionStatus.All
        ) {
          break;
        }
        if (fromView === FromViewType.CompletionDashboard) {
          queryString +=
            'completion_status=' +
            encodeURIComponent(appliedFilters.completion_status) +
            '&';
        } else {
          if (
            appliedFilters.completion_status &&
            appliedFilters.completion_status !==
              TaskStoreCompletionStatus.Overdue
          ) {
            queryString +=
              'completion_status=' +
              encodeURIComponent(appliedFilters.completion_status) +
              '&';
          } else {
            queryString +=
              'completion_status=' +
              encodeURIComponent(TaskStoreCompletionStatus.Incomplete) +
              '&';
          }
        }

        break;
      case 'min_selected_date':
        if (fromView === FromViewType.CompletionDashboard) {
          queryString +=
            'min_selected_date=' +
            encodeURIComponent(appliedFilters.min_selected_date.toString()) +
            '&';
        }
        break;
      case 'max_selected_date':
        if (fromView === FromViewType.CompletionDashboard) {
          queryString +=
            'max_selected_date=' +
            encodeURIComponent(appliedFilters.max_selected_date.toString()) +
            '&';
        }
        break;
      // Calendar View.
      case 'current_date':
        if (appliedFilters.current_date) {
          queryString +=
            'current_date=' +
            encodeURIComponent(appliedFilters.current_date) +
            '&';
        }
        break;
      case 'view_type':
        if (appliedFilters.view_type) {
          queryString +=
            'view_type=' + encodeURIComponent(appliedFilters.view_type) + '&';
        }
        break;
      default:
        break;
    }
  });
  queryString += 'from_view=' + encodeURIComponent(fromView);

  return queryString;
};
