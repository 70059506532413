import { env } from '../envConfig';
import { getRequest } from './apiUtils';
import { IFileInformation } from '../components/types/IFileInformation';
import axios from 'axios';
import { apiRequestConfig } from './apiV1Task';

type ActionTypes = 'read' | 'write' | 'delete';
export const generateSignedUrl = async (
  fileName: string,
  action: ActionTypes,
  attachmentsId?: string
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/files/signedUrl`;
  const params = { fileName: `${attachmentsId}/${fileName}`, action };
  return getRequest(url, params);
};

export const copyAttachmentsToNewGCPBucket = async (
  oldId: string | undefined,
  newId: string,
  attachmentsInformation: IFileInformation[] | undefined
) => {
  const attachmentsNameList = attachmentsInformation?.map(
    (attachment: IFileInformation) => attachment.name
  );
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/files/gcpCopyFiles`;
  const whitelist = [url]
  if(whitelist.includes(url)) {
    await axios.post(
        url,
        {
          oldId,
          newId,
          attachmentsNameList,
        },
        await apiRequestConfig()
    );
  } else {
    console.log('You dont have permission to POST from that url')
  }
};

export const deleteUploadedFile = async (
  fileName: string,
  attachmentsId?: string
) => {
  console.info('DELETING UPLOADED FILE');
  const { signedUrl } = await generateSignedUrl(
    fileName,
    'delete',
    attachmentsId
  );
  const url = signedUrl;
  const whitelist = [url]
  if(whitelist.includes(url)) {
    const response = await axios({
      method: 'delete',
      url,
    });
    return response;
  }  else {
    console.log('You dont have permission to DELETE from that url')
    return null
  }

};
