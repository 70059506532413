import BaseButton from '../BaseButton';
import { theme } from '../../../assets/siteLineTheme';
import dateSelectionForDisplayStore, {
  dateLabelGenerator,
  DateRangeTypeEnum,
} from '../../../stores/dateSelectionForDisplayStore';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import { removeTime } from '../../../utils/dateUtils';

export const IntervalTimeRangeButtonsArray = (colorMode: ColorModeTypeEnum) => {
  const setDateSelection = dateSelectionForDisplayStore.getState().setDateSelectionForDisplayProps;
  const getDateSelection = dateSelectionForDisplayStore.getState().dateSelectionForDisplayProps;

  const getAppliedFilters = appliedFiltersStore.getState().appliedFilters;
  const setAppliedFilters = appliedFiltersStore.getState().setAppliedFilters;

  return [
    <BaseButton
      key={0}
      label={'change to day time range'}
      onClick={() => {
        setDateSelection({
          dateRangeType: DateRangeTypeEnum.DAY,
          selectedDisplayDate: getDateSelection.selectedDisplayDate,
          dateLabel: dateLabelGenerator({
            dateRangeTypeEnum: DateRangeTypeEnum.DAY,
            selectedDisplayDate: getDateSelection.selectedDisplayDate,
          }),
        });
        setAppliedFilters({
          ...getAppliedFilters,
          current_date: removeTime(new Date(getDateSelection.selectedDisplayDate)).toISOString(),
          view_type: DateRangeTypeEnum.DAY.toLowerCase(),
        });
      }}
      activeBackgroundColor={theme.newColorPalette.zoidbergBlack[colorMode]}
      activeTextColor={theme.newColorPalette.plainBlackWhite[colorMode]}
      passiveBackgroundColor={theme.newColorPalette.plainWhiteGrey[colorMode]}
      passiveTextColor={theme.newColorPalette.plainBlackWhite[colorMode]}
      isSelected={getDateSelection.dateRangeType === DateRangeTypeEnum.DAY}
      changeColorWhenSelected={getDateSelection.dateRangeType === DateRangeTypeEnum.DAY}
    >
      Day
    </BaseButton>,
    <div key={1} style={{ display: 'flex', height: '100%' }}>
      <div
        style={{
          width: '2px',
          height: 'auto',
          border: '0.5px solid white',
          margin: '0 0.5em',
          backgroundColor: theme.newColorPalette.plainWhiteBlack[colorMode],
        }}
      />
      <BaseButton
        label={'change to week time range'}
        onClick={() => {
          setDateSelection({
            dateRangeType: DateRangeTypeEnum.WEEK,
            selectedDisplayDate: getDateSelection.selectedDisplayDate,
            dateLabel: dateLabelGenerator({
              dateRangeTypeEnum: DateRangeTypeEnum.WEEK,
              selectedDisplayDate: getDateSelection.selectedDisplayDate,
            }),
          });

          setAppliedFilters({
            ...getAppliedFilters,
            current_date: removeTime(new Date(getDateSelection.selectedDisplayDate)).toISOString(),
            view_type: DateRangeTypeEnum.WEEK.toLowerCase(),
          });
        }}
        activeBackgroundColor={theme.newColorPalette.zoidbergBlack[colorMode]}
        activeTextColor={theme.newColorPalette.plainBlackWhite[colorMode]}
        passiveBackgroundColor={theme.newColorPalette.plainWhiteGrey[colorMode]}
        passiveTextColor={theme.newColorPalette.plainBlackWhite[colorMode]}
        isSelected={getDateSelection.dateRangeType === DateRangeTypeEnum.WEEK}
        changeColorWhenSelected={getDateSelection.dateRangeType === DateRangeTypeEnum.WEEK}
      >
        Week
      </BaseButton>
    </div>
  ];
};
