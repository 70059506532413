import LetterIcon from '../../Icons/LetterIcon';
import React, { useContext, useState } from 'react';
import { commentsAccordionContentStyles } from './commentsAccordionContentStyles';
import { formatDateForDisplay } from '../../../utils/dateUtils';
import { IconButton } from '../../Buttons/IconButton';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from '../../../assets/siteLineTheme';
import { AppContext } from '../../../context/AppContext';
import CommentCreateUpdate from './CommentCreateUpdate';
import { DialogModalSwitchEnum } from '../../Modal/DialogModalSwitch';
import baseModalStore, {
  baseModalContentStoreState,
} from '../../../stores/baseModalStore';
import confirmModalStore from '../../../stores/confirmModalStore';
import { deleteTaskCommentById } from '../../../api/apiV1Task';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';
import innerBaseModalStore from '../../../stores/innerBaseModalStore';

export type CommentProps = {
  comment: CommentsDataType;
  onItemClick?: Function | undefined;
};

export type CommentsDataType = {
  id: string;
  task_id: string;
  store_number: string;
  date_created: string;
  content: string;
  emp_id: string;
  emp_first_name: string;
  emp_last_name: string;
  purge: boolean;
};

const Comment: React.FC<CommentProps> = (props) => {
  const commentsAccordionContentClasses = commentsAccordionContentStyles();
  const [isEditTask, setIsEditTask] = useState<boolean>(false);

  const { comment, onItemClick } = props;
  const firstInitial = `${comment.emp_first_name}`;
  const initial = firstInitial.substring(0, 1) + `${comment.emp_last_name}`;
  const formattedDateForDisplay = formatDateForDisplay(
    `${comment.date_created}`
  );
  const { appState } = useContext<any>(AppContext);
  const { userInfo } = appState;

  const { isActive, setBaseModalContent, resetBaseModalContent } =
    baseModalStore(
      (state: {
        setBaseModalContent: Function;
        resetBaseModalContent: Function;
        baseModalContentStoreProps: baseModalContentStoreState;
      }) => ({
        setBaseModalContent: state.setBaseModalContent,
        isActive: state.baseModalContentStoreProps.isActive,
        resetBaseModalContent: state.resetBaseModalContent,
      })
    );

  const { setInnerBaseModalContent, resetInnerBaseModalContent } =
    innerBaseModalStore(
      (state: {
        setInnerBaseModalContent: Function;
        resetInnerBaseModalContent: Function;
      }) => ({
        setInnerBaseModalContent: state.setInnerBaseModalContent,
        resetInnerBaseModalContent: state.resetInnerBaseModalContent,
      })
    );

  const { setConfirmModal } = confirmModalStore(
    (state: { setConfirmModal: Function }) => ({
      setConfirmModal: state.setConfirmModal,
    })
  );

  const editComment = () => {
    setIsEditTask(!isEditTask);
  };

  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const deleteCommentModal = async (id: string) => {
    if (isActive) {
      setInnerBaseModalContent({
        isActive: true,
        width: windowDimensions.width > 420 ? 0.5 : 0.8,
        height: windowDimensions.width > 420 ? 0.1 : 0.2,
        dialogModalSwitchEnum: DialogModalSwitchEnum.ConfirmDeleteComment,
      });
    } else {
      setBaseModalContent({
        isActive: true,
        width: windowDimensions.width > 420 ? 0.5 : 0.8,
        height: windowDimensions.width > 420 ? 0.1 : 0.2,
        dialogModalSwitchEnum: DialogModalSwitchEnum.ConfirmDeleteComment,
      });
    }

    setConfirmModal({
      message: 'Are you sure you want to delete this message?',
      confirmButtonText: 'Delete',
      confirmOnClickAction: () => deleteComment(id),
    });
  };

  const deleteComment = async (id: string) => {
    await deleteTaskCommentById(id);
    if (onItemClick !== undefined) {
      onItemClick(comment.task_id, comment.store_number);
    }
    sendAnalyticsEvent(analyticsEventConstants.DELETE_COMMENT);

    /* reset Modal */
    if (isActive) {
      resetInnerBaseModalContent();
    } else {
      resetBaseModalContent();
    }
  };

  return (
    <div style={commentsAccordionContentClasses.commentContainer}>
      <div style={commentsAccordionContentClasses.iconContainer}>
        <LetterIcon text={initial} />
      </div>
      <div style={commentsAccordionContentClasses.commentMainContainer}>
        <div style={commentsAccordionContentClasses.commentHeaderContainer}>
          <div key={comment.id} style={commentsAccordionContentClasses.title}>
            {comment.emp_first_name} {comment.emp_last_name}
          </div>
          <div style={commentsAccordionContentClasses.dateText}>
            {formattedDateForDisplay}
          </div>
        </div>

        <div style={commentsAccordionContentClasses.commentTextContainer}>
          {isEditTask ? (
            <CommentCreateUpdate
              existComment={comment.content}
              comment_id={comment.id}
              task_id={comment.task_id}
              updateParentState={onItemClick}
              setIsCreatingComment={setIsEditTask}
            />
          ) : (
            <>
              <div style={commentsAccordionContentClasses.commentText}>
                {comment.content}
              </div>
              <div
                style={commentsAccordionContentClasses.commentButtonContainer}
              >
                <div
                  style={commentsAccordionContentClasses.commentIconContainer}
                >
                  {/* If you create your comment, you can see edit button */}
                  {userInfo.empID === comment.emp_id && !isActive && (
                    <IconButton
                      label={'Edit button'}
                      icon={IconEnum.Edit}
                      onClick={editComment}
                      color={theme.colorPalette.taskTypes.dark["Training & Recognition"]}
                      size={'1.3em'}
                    />
                  )}
                </div>
                {/* If you create your comment, you can see delete button, or if you are higher level, you can see the delete button to delete others. */}
                {(userInfo.empID === comment.emp_id ||
                  !userInfo.SiteLineGroups.includes(
                    'siteline-storeassociate'
                  )) && (
                  <IconButton
                    label={'Delete button'}
                    icon={IconEnum.Delete}
                    onClick={() => deleteCommentModal(comment.id)}
                    color={theme.colorPalette.snackbar.error}
                    size={'1.4em'}
                    margin={'0 0 0 0.4em'}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comment;
