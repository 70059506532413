import React, { ChangeEvent, useContext, useState } from 'react';
import confirmModalStore, {
  ConfirmModalStoreState,
} from '../../../stores/confirmModalStore';
import baseModalStore from '../../../stores/baseModalStore';
import BaseButton from '../../Buttons/BaseButton';
import { theme } from '../../../assets/siteLineTheme';
import { confirmModalStyles } from '../confirmModalStyles';
import { createEditNote, CreateEditNoteValues } from '../../../api/apiV1Task';
import { AppContext } from '../../../context/AppContext';
import { editNotesSubmissionModalStyles } from './editNotesSubmissionModalStyles';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import fireUseQueryStore from '../../../stores/fireUseQueryStore';

const EditNotesSubmissionModal: React.FC = () => {
  const { confirmModalStoreProps } = confirmModalStore(
    (state: { confirmModalStoreProps: ConfirmModalStoreState }) => ({
      confirmModalStoreProps: state.confirmModalStoreProps,
    })
  );
  const { resetBaseModalContent } = baseModalStore(
    (state: { resetBaseModalContent: Function }) => ({
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );
  const { message, confirmButtonText, confirmOnClickAction, task_id } =
    confirmModalStoreProps;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { setFireUseQuery } = fireUseQueryStore(
    (state: { setFireUseQuery: any }) => ({
      setFireUseQuery: state.setFireUseQuery,
    })
  );

  const confirmModalClasses = confirmModalStyles();

  const [editNotes, setEditNotes] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setEditNotes(event.target.value);
  };

  const { appState } = useContext(AppContext);
  const { userInfo } = appState;

  const handleEditNoteSubmit = async () => {
    const lastNameInitial = userInfo.family_name.substring(0, 1);
    const values: CreateEditNoteValues = {
      notes: editNotes,
      emp_first_name: userInfo.given_name,
      emp_id: userInfo.empID,
      emp_last_name: lastNameInitial,
    };
    sendAnalyticsEvent(analyticsEventConstants.EDIT_TASK);
    if (task_id !== undefined) {
      await createEditNote(task_id, values);
    }
    setFireUseQuery({ triggerTime: new Date(Date.now()) });
  };

  const editNotesSubmissionModalClasses = editNotesSubmissionModalStyles();

  return (
    <>
      <div style={confirmModalClasses.confirmModalContainer}>
        <div style={confirmModalClasses.confirmModalMessageContainer}>
          {message}
        </div>
        <textarea
          defaultValue={editNotes}
          placeholder="Add reason for edit..."
          onChange={(e) => handleChange(e)}
          style={editNotesSubmissionModalClasses.textArea}
          maxLength={500}
        />

        <div style={confirmModalClasses.confirmModalButtonContainer}>
          <BaseButton
            onClick={() => resetBaseModalContent()}
            activeBackgroundColor={
              theme.newColorPalette.buttons.commentLightCancelDarkGrey[
                userPreference.colorMode
              ]
            }
            passiveBackgroundColor={
              theme.newColorPalette.buttons.commentDarkCancelBlack[
                userPreference.colorMode
              ]
            }
            activeTextColor={theme.colorPalette.plainWhite}
            passiveTextColor={theme.colorPalette.plainWhite}
            fontSize={'0.9em'}
            label={'Cancel'}
          >
            Cancel
          </BaseButton>
          <BaseButton
            onClick={() => {
              if (editNotes.length > 0) {
                handleEditNoteSubmit();
                resetBaseModalContent();
                confirmOnClickAction();
              } else {
                window.alert(
                  "Editor's Notes detailing changes made to this task are required for submission."
                );
              }
            }}
            activeBackgroundColor={
              theme.newColorPalette.buttons.commentLightSubmitDarkGrey[
                userPreference.colorMode
              ]
            }
            passiveBackgroundColor={
              theme.newColorPalette.buttons.commentDarkSubmitBlack[
                userPreference.colorMode
              ]
            }
            activeTextColor={theme.colorPalette.plainWhite}
            passiveTextColor={theme.colorPalette.plainWhite}
            fontSize={'0.9em'}
            margin={'0 0 0 2em'}
            label={`${confirmButtonText}`}
          >
            {confirmButtonText}
          </BaseButton>
        </div>
      </div>
    </>
  );
};

export default EditNotesSubmissionModal;
