import {getSavedFilter} from "../api/apiEmployee";
import {groups} from "../context/constants";
import {TaskStatus} from "../models/TaskStatus";
import {AppliedFiltersProps} from "../stores/models/AppliedFiltersProps";

export const parseSavedFilter = async (userInfo: any, userOktaGroup: any, setAppliedFilters: any, appliedFilters: any) => {
    const getFilterInformList = await getSavedFilter();

    const getLocationInformation = () => {
        // if user is a michael or stanley,
        // we want to make sure that the saved location information is their okta store/district/region/territory number
        if (userOktaGroup === groups.MICHAEL || userOktaGroup === groups.STANLEY) {
            return {
                territory_number: undefined,
                region_number: undefined,
                district_number: undefined,
                store_number: userInfo.locationnumber
            }
        } else {
            return {
                territory_number:
                    getFilterInformList.territory_number !== null
                        ? getFilterInformList.territory_number
                        : undefined,
                region_number:
                    getFilterInformList.region_number !== null
                        ? getFilterInformList.region_number
                        : undefined,
                district_number:
                    getFilterInformList.district_number !== null
                        ? getFilterInformList.district_number
                        : undefined,
                store_number:
                    getFilterInformList.store_number !== null
                        ? getFilterInformList.store_number
                        : undefined,
            }
        }
    }


    if (typeof getFilterInformList !== 'string') {
            setAppliedFilters({
                ...appliedFilters,
                ...getFilterInformList,
                ...getLocationInformation(),
                isSavedFilter: true,
            });
        } else {
            if (userOktaGroup === groups.JAN) {
                if (
                    appliedFilters.store_number ||
                    appliedFilters.district_number ||
                    appliedFilters.region_number ||
                    appliedFilters.territory_number
                ) {
                    setAppliedFilters({
                        ...appliedFilters,
                        task_status: TaskStatus.Approved,
                        isSavedFilter: true,
                        completion_status: "Incomplete"
                    });
                } else {
                    setAppliedFilters({
                        ...appliedFilters,
                        territory_number: userInfo.territorynumber,
                        region_number: userInfo.regionnumber,
                        district_number: userInfo.districtnumber,
                        task_status: TaskStatus.Approved,
                        isSavedFilter: true,
                        completion_status: "Incomplete"
                    });
                }
            } else if (userOktaGroup === groups.MICHAEL || userOktaGroup === groups.STANLEY) {
                setAppliedFilters({
                    ...appliedFilters,
                    territory_number: undefined,
                    region_number: undefined,
                    district_number: undefined,
                    store_number: userInfo.locationnumber,
                    task_status: TaskStatus.Approved,
                    isSavedFilter: true,
                    completion_status: "Incomplete"
                });
            } else {
                setAppliedFilters({
                    ...appliedFilters,
                    completion_status: "Incomplete"
                });
            }
        }
}

export const checkIfAppliedFilterLocationsExist = (appliedFilters: any)=> {
    return (appliedFilters.territory_number !== undefined || appliedFilters.region_number !== undefined || appliedFilters.district_number !== undefined || appliedFilters.store_number !== undefined)
}

export const isFiltersReady = (appliedFilters: AppliedFiltersProps, userOktaGroup: string) => {
    return ((appliedFilters.isSavedFilter === true && checkIfAppliedFilterLocationsExist(appliedFilters)) || ((userOktaGroup === groups.SUPER_GABE || userOktaGroup === groups.GABE || userOktaGroup === groups.JAN) && userOktaGroup !== undefined));
}