import React from 'react';
import { createOrUpdateEmployeePreference } from '../../../api/apiEmployee';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { viewTypeStyles } from './viewTypeStyles';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from 'src/analytics/analytics';

const ViewRowNumber: React.FC = () => {
  const { userPreference, setUserPreference } = userPreferenceStore(
    (state: {
      userPreference: UserPreferenceStoreState;
      setUserPreference: any;
    }) => ({
      userPreference: state.userPreference,
      setUserPreference: state.setUserPreference,
    })
  );

  const setToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserPreference({
      ...userPreference,
      viewRowNumber: Number(value),
    });
    sendAnalyticsEvent(analyticsEventConstants.TOGGLE_VIEW_ROWNUMBER);
    await createOrUpdateEmployeePreference({
      color_mode_type: userPreference.colorMode,
      view_type: userPreference.viewType,
      view_row_number: Number(value),
    });
  };

  const viewTypeClasses = viewTypeStyles();

  return (
    <>
      <div style={viewTypeClasses.viewTypeContainer}>
        <div style={viewTypeClasses.viewTypeTitle}>View RowNumber</div>
        <div style={viewTypeClasses.viewTypeRadioContainer}>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
              type={'radio'}
              name={'viewRowNumber'}
              value={10}
              checked={userPreference.viewRowNumber === 10}
              onChange={setToggle}
            />
            <div style={viewTypeClasses.viewTypeText}> 10 </div>
          </div>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
              type={'radio'}
              name={'viewRowNumber'}
              value={20}
              checked={userPreference.viewRowNumber === 20}
              onChange={setToggle}
            />
            <div style={viewTypeClasses.viewTypeText}> 20 </div>
          </div>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
              type={'radio'}
              name={'viewRowNumber'}
              value={50}
              checked={userPreference.viewRowNumber === 50}
              onChange={setToggle}
            />
            <div style={viewTypeClasses.viewTypeText}> 50 </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRowNumber;
