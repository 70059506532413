import React from 'react'
import IconButtonWithLabel from "../Buttons/IconButtonWithLabel";
import {IconEnum} from "../Chip/chipUtils/ChipEnums";
import {theme} from "../../assets/siteLineTheme";
import {analyticsEventConstants, sendAnalyticsEvent} from "../../analytics/analytics";

export type DigitalMerchandisingFormTypeSelectionProps = {
    digitalObj: any,
    removeEmptyMenu: Function,
    handlerButtonClick: Function,
    setDigitalObj: Function,
    userPreference: any,
    deleteModal: Function,
    clearNavigationArrays: Function,
    setIsCreate: Function
}

const DigitalMerchandisingFormTypeSelection: React.FC<DigitalMerchandisingFormTypeSelectionProps> = (props) => {
    const {digitalObj, removeEmptyMenu, handlerButtonClick, setDigitalObj, userPreference, deleteModal, clearNavigationArrays, setIsCreate} = props

    return (
        <div className={'formSelectionsContainer'}
             style={{
                 display: 'flex',
                 flexDirection: 'row',
                 justifyContent: 'center',
                 margin: '0.5em 0',
             }}>
            {(digitalObj.selectItem?.link_type === null ||
                digitalObj.selectItem === undefined) && (
                <IconButtonWithLabel
                    label={'Add Menu'}
                    buttonLabel={'Add'}
                    icon={IconEnum.PlusSign}
                    onClick={() => {
                        if (digitalObj.isAdd || digitalObj.isModify) {
                            window.alert(
                                "Nope. What you're attempting to do, this whole adding a folder inside an empty folder thing, it's not a good idea and I'm stopping you."
                            );
                        } else {
                            removeEmptyMenu();
                            handlerButtonClick({
                                digitalObj,
                                setDigitalState: setDigitalObj,
                                type: 'Add',
                            });
                        }
                    }}
                    textColor={
                        theme.newColorPalette.plainBlackWhite[userPreference.colorMode]
                    }
                    borderRadius={'1em'}
                    color={theme.colorPalette.plainWhite}
                    backgroundColor={
                        theme.newColorPalette.snackbar.successBlack[
                            userPreference.colorMode
                            ]
                    }
                />
            )}

            <IconButtonWithLabel
                label={'Edit Menu'}
                buttonLabel={'Edit'}
                icon={IconEnum.Edit}
                onClick={() => {
                    removeEmptyMenu();
                    handlerButtonClick({
                        digitalObj,
                        setDigitalState: setDigitalObj,
                        type: 'Edit',
                    });
                }}
                textColor={
                    theme.newColorPalette.plainBlackWhite[userPreference.colorMode]
                }
                borderRadius={'1em'}
                margin={'0 0 0 0.7em'}
                color={theme.colorPalette.plainWhite}
                backgroundColor={
                    theme.newColorPalette.snackbar.successBlack[
                        userPreference.colorMode
                        ]
                }
            />
            <IconButtonWithLabel
                label={'Delete Menu'}
                buttonLabel={'Delete'}
                icon={IconEnum.Delete}
                onClick={() => deleteModal()}
                textColor={theme.colorPalette.plainBlack}
                borderRadius={'1em'}
                margin={'0 0 0 0.7em'}
                color={
                    theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
                }
                backgroundColor={
                    theme.newColorPalette.zoidbergGrey[userPreference.colorMode]
                }
            />
            <IconButtonWithLabel
                label={'Go Back'}
                buttonLabel={'Exit Editing'}
                icon={IconEnum.Reset}
                onClick={() => {
                    clearNavigationArrays();
                    setIsCreate(false);
                    sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_EXIT_MENU);
                }}
                textColor={
                    theme.newColorPalette.textDarkBlack[userPreference.colorMode]
                }
                borderRadius={'1em'}
                margin={'0 0 0 0.7em'}
                color={
                    theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
                }
                backgroundColor={
                    theme.newColorPalette.zoidbergGrey[userPreference.colorMode]
                }
            />
        </div>
    )
}

export default DigitalMerchandisingFormTypeSelection