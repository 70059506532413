import {AppBar, Box, Drawer, Toolbar} from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/Full-Logo.svg';
import TaskManagementNavigation from '../TaskManagementNavigation/TaskManagementNavigation';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { theme } from '../../assets/siteLineTheme';
import { AppContext } from 'src/context/AppContext';
import SearchComponent from './SearchComponent';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../analytics/analytics';
import userPreferenceStore, {
  UserPreferenceStoreState,
  ViewTypeEnum,
} from 'src/stores/userPreferenceStore';
import { routeDetails } from 'src/screens/utils/routeConstants';
import { listRouteItems } from './AppHeaderUtils';
import { IconButton } from '../Buttons/IconButton';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { DrawerContentType } from '../../stores/models/DrawerProps';
import drawerPropsStore from '../../stores/drawerPropsStore';
import {
  getNewFeatures,
  getNewFeaturesViewedEmployee,
} from '../../api/apiNewFeature';
import LetterIcon from '../Icons/LetterIcon';
import baseModalStore from '../../stores/baseModalStore';
import { DialogModalSwitchEnum } from '../Modal/DialogModalSwitch';
import { getEmployeePreference } from 'src/api/apiEmployee';
import muiTheme from '../../utils/mui/muiTheme'

const AppHeaderBar: React.FC = () => {
  const { appState } = useContext(AppContext);
  const { screens, userInfo } = appState;
  const [initial, setInitial] = useState<string>('');

  const { userPreference, setUserPreference } = userPreferenceStore(
    (state: {
      userPreference: UserPreferenceStoreState;
      setUserPreference: any;
    }) => ({
      userPreference: state.userPreference,
      setUserPreference: state.setUserPreference,
    })
  );

  const screenSizeFunction = () => {
    return window.innerWidth > 421;
  };
  const [isSearchDisplayedForMobile, setIsSearchDisplayedForMobile] =
    useState<boolean>(screenSizeFunction);

  const [
    isTaskManagementNavigationDrawerOpen,
    setIsTaskManagementNavigationDrawerOpen,
  ] = useState(false);

  const handleDrawerOpen = () => {
    setIsTaskManagementNavigationDrawerOpen(true);
  };

  const pathName = window.location.pathname;
  const [showTwoToneBackground, setShowTwoToneBackground] =
    useState<boolean>(true);
  useEffect(() => {
    const listPathNames: string[] = listRouteItems(routeDetails);
    listPathNames.includes(pathName)
      ? setShowTwoToneBackground(true)
      : setShowTwoToneBackground(false);
  }, [pathName]);

  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const [newFeatureList, setNewFeatureList] = useState([]);
  const [viewedFeatureList, setViewedFeatureList] = useState([]);

  const getNewFeaturesList = useCallback(async () => {
    const returnNewFeatureList = await getNewFeatures();
    const returnViewedFeatureList = await getNewFeaturesViewedEmployee();

    setNewFeatureList(returnNewFeatureList);
    setViewedFeatureList(returnViewedFeatureList);
  }, []);

  useEffect(() => {
    if (drawerProps.drawerContentType === DrawerContentType.NewFeatures) {
      getNewFeaturesList();
    }
  }, [drawerProps.isDrawerOpen]);

  useEffect(() => {
    if (userInfo.name !== undefined) {
      getNewFeaturesList();
      setInitial(
        userInfo.given_name.substring(0, 1) +
          ' ' +
          userInfo.family_name.substring(0, 1)
      );
    }
  }, [userInfo.name]);

  const viewNotification = () => {
    sendAnalyticsEvent(analyticsEventConstants.NOTIFICATION_VIEW);
    setDrawerProps({
      drawerContentType: DrawerContentType.NewFeatures,
      isDrawerOpen: true,
      drawerContentProps: {
        newFeaturesList: newFeatureList,
        viewedFeaturesList: viewedFeatureList,
      },
    });
  };

  const { setBaseModalContent } = baseModalStore(
    (state: { setBaseModalContent: Function }) => ({
      setBaseModalContent: state.setBaseModalContent,
    })
  );

  const windowDimensions = {
    width: window.innerWidth,
  };

  const preferenceClick = () => {
    setBaseModalContent({
      isActive: true,
      width: windowDimensions.width > 420 ? 0.2 : 0.75,
      height: windowDimensions.width > 420 ? 0.25 : 0.35,
      top: 45,
      right: 10,
      dialogModalSwitchEnum: DialogModalSwitchEnum.UserPreference,
    });
    sendAnalyticsEvent(analyticsEventConstants.SELECT_PROFILE);
  };

  const getUserPreference = useCallback(async () => {
    const employeePreference = await getEmployeePreference();
    if (
      employeePreference !== undefined &&
      employeePreference.color_mode_type !== undefined
    ) {
      setUserPreference({
        ...userPreference,
        colorMode: employeePreference.color_mode_type,
        viewType: employeePreference.view_type
          ? employeePreference.view_type
          : windowDimensions.width > 420
          ? ViewTypeEnum.Calendar
          : ViewTypeEnum.List,
        viewRowNumber: employeePreference.view_row_number
          ? employeePreference.view_row_number
          : 10,
      });
    }
  }, []);

  useEffect(() => {
    getUserPreference();
  }, []);

  return (
    <div id={'AppHeaderBar'}>
      <Drawer
        anchor="left"
        open={isTaskManagementNavigationDrawerOpen}
        onClose={() => setIsTaskManagementNavigationDrawerOpen(false)}
        // classes={{ paperAnchorLeft: classes.paperAnchorLeft }}
        PaperProps={{
          sx: {
            width: '20%',
            [muiTheme.breakpoints.between('xs', 'sm')]: {
              width: '100%',
            },
            minWidth: '320px',
          }
        }}
      >
        <TaskManagementNavigation
          setIsTaskManagementNavigationDrawerOpen={
            setIsTaskManagementNavigationDrawerOpen
          }
          menuList={screens}
          colorMode={userPreference.colorMode}
        />
      </Drawer>
      <AppBar position="static"
              style={{
                backgroundColor: theme.newColorPalette.zoidbergWhite[userPreference.colorMode],
                color: theme.newColorPalette.textDarkBlack[userPreference.colorMode]
              }}
      >
        <Toolbar>
          <IconButton
            label={'Menu'}
            icon={IconEnum.Menu}
            color={theme.colorPalette.textDark}
            size={'1.3em'}
            onClick={handleDrawerOpen}
            margin={'0 0.5em 0 0'}
          />
          {!isSearchDisplayedForMobile && (
            <IconButton
              label={'Search'}
              icon={IconEnum.Search}
              color={theme.colorPalette.textDark}
              size={'1.5em'}
              onClick={() => setIsSearchDisplayedForMobile(true)}
            />
          )}
          {(window.innerWidth > 421 ||
            (window.innerWidth < 421 && isSearchDisplayedForMobile)) && (
            <SearchComponent />
          )}

          {(window.innerWidth > 421 ||
            (window.innerWidth < 421 && !isSearchDisplayedForMobile)) && (
            <Box // className={classes.logoBox}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'center',
                }}
                >
              <Logo title="siteline-logo" />
            </Box>
          )}
          <Box style={{ flexGrow: 1 }} />
          <IconButton
            label={'notifications'}
            icon={
              newFeatureList !== undefined && newFeatureList.length !== 0
                ? IconEnum.NotificationWithRedCircle
                : IconEnum.Notification
            }
            color={theme.colorPalette.textDark}
            size={'1.7em'}
            onClick={() => viewNotification()}
            margin={'0 0.4em 0 0'}
          />
          <LetterIcon text={initial} onClickFunc={preferenceClick} />
        </Toolbar>
      </AppBar>

      {showTwoToneBackground && <Box
            sx={{
              boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
              height: '33vh',
              backgroundColor: theme.newColorPalette.backgroundDarkWhite[userPreference.colorMode],
              width: '100%',
              position: 'absolute',
            }}
          // className={classes.backgroundBox} />
      />
      }
    </div>
  );
};

export default AppHeaderBar;
