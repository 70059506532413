import {safetyRiskComplianceDataWrapperStyles} from "./styles/safteyRiskComplianceDataWrapperStyles";
import {analyticsEventConstants, sendAnalyticsEvent} from "../analytics/analytics";
import BoxArray, {BoxContentTypeEnum} from "../components/BoxArray/BoxArray";
import {useHistory, useLocation, useParams} from "react-router-dom";
import ViewPDF from "../components/ViewAttachments/ViewPDF";
import {parseFilesUploadType} from "../utils/fileUploadUtils/parseFilesUploadType";
import {useEffect, useState} from "react";
import {generateSignedUrl} from "../api/apiFile";
import {IconButton} from "../components/Buttons/IconButton";
import {IconEnum} from "../components/Chip/chipUtils/ChipEnums";
import {useFetchSafetyRiskComplianceData} from "../hooks/useFetchSafetyRiskComplianceData";
import fireUseQueryStore from "../stores/fireUseQueryStore";
import {NewMerchMenuObjPDFType} from "../api/apiDigitalMerchandising";
import zoidberg from "../assets/images/zoidberg-waiting.jpg"

export type SafetyRiskObj = {
    id: string;
    title: string;
    file_information?: NewMerchMenuObjPDFType;
};

const SafetyRiskComplianceDataWrapper = () => {
    const [signedURL, setSignedURL] = useState('');
    const [currentNavObj, setCurrentNavObj] = useState<SafetyRiskObj | undefined>(undefined);
    const safetyRiskComplianceDataWrapperClasses = safetyRiskComplianceDataWrapperStyles();
    const {pathname} = useLocation();
    const {subPath} = useParams<{ subPath: string }>();

    const history = useHistory();

    const setHistory = (pathname: string) => {
        history.push({
            pathname,
            state: {
                // location state
                update: true,
            },
        });
    };

    const handleDigitalMerchItemClicked = (
        name: string,
        event: { action: string; category: string; label: string }
    ) => {
        sendAnalyticsEvent({
            action: event.action,
            category: event.category,
            label: event.label,
            state: decodeURIComponent(name),
        });
    };

    const handleSetNavigationDisplay = (navigationObj: SafetyRiskObj) => {
        setHistory(pathname + '/' + navigationObj.title)

        handleDigitalMerchItemClicked(
            navigationObj.title,
            analyticsEventConstants.SAFETY_VIDEO
        );
    };

    useEffect(() => {
        const getFileSignedUrl = async (id: string, fileName: string) => {
            const { signedUrl } = await generateSignedUrl(fileName, 'read', id);
            setSignedURL(signedUrl);
        };

        currentNavObj !== undefined && currentNavObj.file_information?.attachments_id !== undefined &&
        getFileSignedUrl(
            currentNavObj.file_information?.attachments_id,
            currentNavObj.file_information?.name !== undefined
                ? currentNavObj.file_information?.name
                : ''
        );
    }, [currentNavObj]);

    const {fireUseQueryStoreProps} = fireUseQueryStore(
        (state: { fireUseQueryStoreProps: any }) => ({
            fireUseQueryStoreProps: state.fireUseQueryStoreProps,
        })
    );

    const {data, isSuccess, error} = useFetchSafetyRiskComplianceData(fireUseQueryStoreProps.triggerTime);

    useEffect(() => {
        if (isSuccess) {
            if (subPath) {
                const fromUrlObj =
                    data &&
                    data.find((data: SafetyRiskObj) => {
                        if (data.title && subPath) {
                            return (
                                decodeURIComponent(data.title) ===
                                decodeURIComponent(subPath)
                            );
                        } else {
                            return false;
                        }
                    });

                if (fromUrlObj) {
                    setCurrentNavObj(fromUrlObj);
                }
            }
        }
    }, [isSuccess, subPath]);

    useEffect(() => {
        if (error !== null) {
            history.replace('/error');
        }
    }, [error]);

    return (
        <div
            className={'screenContainer'}
            style={safetyRiskComplianceDataWrapperClasses.screenContainer}
        >
            <div
                className={'navSectionTitle'}
                style={
                    safetyRiskComplianceDataWrapperClasses.navSectionTitle
                }
            >
                Safety Risk Compliance Videos
            </div>
            {data !== undefined && data.length > 0 ?
                <div
                    className={'navSection'}
                    style={safetyRiskComplianceDataWrapperClasses.navSection}
                >
                    {
                        currentNavObj === undefined && data !== undefined &&
                        <div>

                            <BoxArray
                                justifyContent={'flex-start'}
                                dataArray={data}
                                boxContentType={
                                    BoxContentTypeEnum.SafetyRiskContent
                                }
                                boxHeight={window.innerWidth > 420 ? '15vh' : '10vh'}
                                boxWidth={`50%`}
                                onClick={handleSetNavigationDisplay}
                                gap={'0.4em 0'}
                            />
                        </div>
                    }
                    {currentNavObj !== undefined &&
                        <div>
                            <div style={safetyRiskComplianceDataWrapperClasses.backButtonContainer}>
                                <IconButton
                                    label={'return to main'}
                                    icon={IconEnum.Previous}
                                    margin={'0 0.2em'}
                                    onClick={() => {
                                        setHistory('/safety-risk-compliance')
                                        setCurrentNavObj(undefined)
                                    }}
                                    color={'white'}
                                    backgroundColor={'gray'}
                                    size={'2em'}
                                    borderRadius={'50%'}
                                />
                                <div style={safetyRiskComplianceDataWrapperClasses.videoTitleContainer}>
                                    {currentNavObj.title}
                                </div>
                            </div>
                            <div
                                style={{
                                    margin:
                                        window.innerWidth > 420
                                            ? '-3.5em 0 0.25em 0'
                                            : '0 0 0.25em 0',
                                    padding: '0 0 0.25em 0',
                                }}
                            >
                                <ViewPDF
                                    contentId={
                                        currentNavObj?.file_information
                                            ?.attachments_id !== undefined ?
                                            currentNavObj.file_information.attachments_id
                                            : ''
                                    }
                                    contentUrl={signedURL}
                                    contentType={parseFilesUploadType(
                                        currentNavObj?.file_information?.type
                                            ? currentNavObj?.file_information.type
                                            : 'pdf'
                                    )}
                                />
                            </div>
                        </div>
                    }
                </div>
                :
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                    flexDirection: 'column' as 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 30
                    }} className={'videosComingSoon'}>Videos coming soon!
                    </div>
                    <img style={{width: 300}} src={zoidberg} alt={'ZoidbergWaiting'}/>
                </div>
            }
        </div>
    )
}

export default SafetyRiskComplianceDataWrapper
