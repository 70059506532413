import FeedbackButton from '../components/Buttons/FeedbackButton';
import {analyticsEventConstants, sendAnalyticsEvent} from '../analytics/analytics';
import IconButtonWithLabel from '../components/Buttons/IconButtonWithLabel';
import {IconEnum} from '../components/Chip/chipUtils/ChipEnums';
import {theme} from '../assets/siteLineTheme';
import PrintButton from '../components/Buttons/PrintButton';
import {Calendar} from '../components/Calendar/Calendar';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import drawerPropsStore from '../stores/drawerPropsStore';
import {DrawerContentType} from '../stores/models/DrawerProps';
import {CalendarEvent} from '../components/types/Calendar/ICalendarIndex';
import {v4 as uuidV4} from 'uuid';
import {
  getTaskDescriptionByTaskId,
  getTaskLevelDetails,
  getTaskStoreCompletionStatus,
  getTaskStoreLevelDetails,
  updateTaskViewedReadReceiptById,
} from '../api/apiV1Task';
import {copyAttachmentsToNewGCPBucket} from '../api/apiFile';
import userPreferenceStore, {UserPreferenceStoreState,} from 'src/stores/userPreferenceStore';
import {AppContext} from '../context/AppContext';

import {format, subDays} from 'date-fns';
import {groups} from '../context/constants';
import {fetchRegionalTaskCompletionStatus} from '../api/apiUtils';
import appliedFiltersStore from '../stores/appliedFiltersStore';
import useFetchUserGroup from '../hooks/fetchUserGroup';
import {reviewApprovalAndCalendarScreenStyles} from 'src/screens/styles/reviewApprovalAndCalendarScreenStyles';
import {appliedFilterQueryStringBuilder, FromViewType,} from '../utils/appliedFilterQueryStringBuilder';
import searchBarPropsStore from '../stores/searchBarPropsStore';
import {SetState} from 'zustand';
import {TaskStoreCompletionStatus} from '../models/TaskStoreCompletionStatus';
import {isDateBeforeToday} from '../utils/dateUtils';
import {useFetchTaskAndPromotionsWithAppliedFilters} from 'src/hooks/useFetchTaskAndPromotionWithAppliedFilters';
import fireUseQueryStore from '../stores/fireUseQueryStore';
import {useHistory} from 'react-router-dom';
import {isFiltersReady, parseSavedFilter} from "../utils/savedFilter";
import {TaskStatus} from "../models/TaskStatus";

export const TaskCalendarDataWrapper: React.FC = () => {
  const { appState } = useContext<any>(AppContext);
  const { userPermissions, userInfo } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const calendarStyles = reviewApprovalAndCalendarScreenStyles({
    colorMode: userPreference.colorMode,
  });
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const history = useHistory();
  const [isFilterSaved, setIsFilterSaved] = useState(false);

  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
      (state: { appliedFilters: any; setAppliedFilters: any }) => ({
        appliedFilters: state.appliedFilters,
        setAppliedFilters: state.setAppliedFilters,
      })
  );

  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const { setSearchBarProps } = searchBarPropsStore(
    (state: { setSearchBarProps: SetState<any> }) => ({
      setSearchBarProps: state.setSearchBarProps,
    })
  );

  const { fireUseQueryStoreProps, setFireUseQuery } = fireUseQueryStore(
    (state: { fireUseQueryStoreProps: any; setFireUseQuery: any }) => ({
      fireUseQueryStoreProps: state.fireUseQueryStoreProps,
      setFireUseQuery: state.setFireUseQuery,
    })
  );

  useEffect(() => {
    if (userOktaGroup) {
      if (userOktaGroup === groups.JAN) {
        if (
          appliedFilters.store_number ||
          appliedFilters.district_number ||
          appliedFilters.region_number ||
          appliedFilters.territory_number
        ) {
          setAppliedFilters({
            ...appliedFilters,
            task_status: TaskStatus.Approved,
          });
        } else {
          setAppliedFilters({
            ...appliedFilters,
            territory_number: userInfo.territory_number,
            region_number: userInfo.region_number,
            district_number: userInfo.district_number,
            task_status: TaskStatus.Approved,
          });
        }
      } else if (
        userOktaGroup === groups.MICHAEL ||
        userOktaGroup === groups.STANLEY
      ) {
        setAppliedFilters({
          ...appliedFilters,
          territory_number: userInfo.territory_number,
          region_number: userInfo.region_number,
          district_number: userInfo.district_number,
          store_number: userInfo.locationnumber,
          task_status: TaskStatus.Approved,
        });
      } else {
        setAppliedFilters({ ...appliedFilters });
      }
    }
    return function cleanup() {};
  }, [userOktaGroup]);

  const openCreate = () => {
    sendAnalyticsEvent(analyticsEventConstants.CREATE_TASK);

    setDrawerProps({
      drawerContentType: DrawerContentType.TaskForm,
      isDrawerOpen: true,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        formMode: 'create',
        viewEvent: null,
      },
    });
  };

  const openEdit = () => {
    setDrawerProps({
      ...drawerProps,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        formMode: 'edit',
      },
    });
  };

  const getNewAttachmentsIdAndCopyFilesInGCP = (viewEvent: CalendarEvent) => {
    const newViewEvent = { ...viewEvent, attachments_id: uuidV4() };
    const oldId = viewEvent.attachments_id;
    if (newViewEvent.attachment_information?.length !== 0) {
      copyAttachmentsToNewGCPBucket(
        oldId,
        newViewEvent.attachments_id,
        newViewEvent.attachment_information
      );
    }
    return newViewEvent;
  };

  const duplicateTaskHandler = () => {
    if (drawerProps.drawerContentProps.viewEvent) {
      const viewEventToSet = getNewAttachmentsIdAndCopyFilesInGCP(
        drawerProps.drawerContentProps.viewEvent
      ) as CalendarEvent;

      setDrawerProps({
        ...drawerProps,
        drawerContentProps: {
          ...drawerProps.drawerContentProps,
          formMode: 'create',
          viewEvent: viewEventToSet,
        },
      });
    }
  };

  const openView = (event: CalendarEvent, wasFiredFromCalendar?: boolean) => {
    setDrawerProps({
      ...drawerProps,
      drawerContentType: DrawerContentType.TaskForm,
      isDrawerOpen: true,
      drawerContentProps: {
        openEdit,
        formMode: 'view',
        viewEvent: event,
        duplicateTaskHandler,
      },
    });
  };

  const findFullEventToOpen = async (
    selectedEvent: any,
    wasFiredFromCalendar?: boolean
  ) => {
    const { id, uc_event, end_date, title, task_type } = selectedEvent;
    sendAnalyticsEvent({ ...analyticsEventConstants.VIEW_TASK, state: title });

    if (userOktaGroup) {
      if (
        (userOktaGroup === groups.MICHAEL ||
          userOktaGroup === groups.STANLEY) &&
        (task_type === 'Sephora' ||
          task_type === 'Merchandising' ||
          task_type === 'Visual' ||
          task_type === 'Holiday')
      ) {
        await updateTaskViewedReadReceiptById(id);
      }
    }

    if (uc_event) {
      openView(
        { ...selectedEvent, end_date: subDays(new Date(end_date), 1) },
        wasFiredFromCalendar
      );
      return;
    }
    const { locationnumber } = userInfo;
    const isMichaelOrStanley =
      userOktaGroup === groups.MICHAEL || userOktaGroup === groups.STANLEY;
    const isDistroEvent = selectedEvent?.distro_file_information?.length;
    const storeLevelDetailsPromise =
      isMichaelOrStanley || appliedFilters.store_number
        ? getTaskStoreLevelDetails(
            id,
            appliedFilters.store_number || locationnumber
          )
        : !isDistroEvent
        ? getTaskStoreLevelDetails(id)
        : {};

    const taskLevelDetails = await getTaskLevelDetails(id);
    taskLevelDetails.distro_file_information =
      taskLevelDetails?.distro_file_information
        ? [taskLevelDetails?.distro_file_information]
        : [];

    let taskDescriptionByTaskId = await getTaskDescriptionByTaskId(id);
    taskDescriptionByTaskId = taskDescriptionByTaskId.ops
      ? { task_description: taskDescriptionByTaskId }
      : taskDescriptionByTaskId;
    const storeLevelDetails = await storeLevelDetailsPromise;
    const regionalTaskStoreCompletionStatus =
      await fetchRegionalTaskCompletionStatus(id);

    let eventObj = {
      ...selectedEvent,
      ...taskLevelDetails,
      ...taskDescriptionByTaskId,
      ...storeLevelDetails,
      ...regionalTaskStoreCompletionStatus,
    };

    const taskStoreCompletionStatus =
      appliedFilters.store_number &&
      appliedFilters.store_number !== 'All' &&
      eventObj.require_completion
        ? await getTaskStoreCompletionStatus(id, appliedFilters.store_number)
        : {};

    eventObj = {
      ...eventObj,
      ...taskStoreCompletionStatus,
    };
    openView({ ...eventObj }, wasFiredFromCalendar);
  };

  useEffect(() => {
    if (userOktaGroup) {
      const savedFiltered = async () => {
        await parseSavedFilter(userInfo, userOktaGroup, setAppliedFilters, appliedFilters)
      }
      try {
        savedFiltered()
      } catch (e) {
        console.error(e)
      }
    }
  }, [userOktaGroup]);


  const [queryString, setQueryString] = useState<string>(
    appliedFilterQueryStringBuilder(appliedFilters, FromViewType.Calendar)
  );

  useEffect(() => {
    setQueryString(
      appliedFilterQueryStringBuilder(appliedFilters, FromViewType.Calendar)
    );
    if (appliedFilters.isSavedFilter) {
      setIsFilterSaved(true)
    }
  }, [appliedFilters]);

  const { data, error } = useFetchTaskAndPromotionsWithAppliedFilters(
    queryString,
    fireUseQueryStoreProps.triggerTime,
    // all users except for superusers (2 calls) have 1 withPromo call, need future refactor for superusers
    ((isFiltersReady(appliedFilters, userOktaGroup) && isFilterSaved) || (userOktaGroup === groups.SUPER_GABE || userOktaGroup === groups.GABE || userOktaGroup === groups.JAN))
  );

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      history.replace('/error');
    }
  }, [error]);

  const setSearchBarData = useCallback(() => {
    if (data !== undefined) {
      const handleTaskSearchClick = (taskId: string) => {
        const matchEvent = data.find(
          (task: CalendarEvent) => task.id === taskId
        );
        findFullEventToOpen(matchEvent);
      };

      setSearchBarProps({
        options: data.map((task: CalendarEvent) => {
          return {
            title: task.title,
            task_type: task.task_type,
            dateRange: `${format(task.start_date, 'MM/dd/yyyy')} - ${format(
              task.end_date,
              'MM/dd/yyyy'
            )}`,
            identifier: task.id,
            optionType: 'task',
          };
        }),
        clickHandler: handleTaskSearchClick,
      });
    }
  }, [data?.length]);

  const setEventFilter = useCallback(() => {
    let eventData;
    if (data !== undefined) {
      eventData = data;

      const today = new Date(Date.now());
      today.setHours(0, 0, 0, 0);

      if (
        appliedFilters.store_number &&
        appliedFilters.completion_status ===
          TaskStoreCompletionStatus.Incomplete
      ) {
        eventData = data.filter((task: CalendarEvent) => {
          return (
            (task.id === undefined && new Date(task.end_date) >= today) ||
            task.id !== undefined
          );
        });
      }

      if (
        appliedFilters.store_number &&
        appliedFilters.completion_status === TaskStoreCompletionStatus.Completed
      ) {
        eventData = data.filter((task: CalendarEvent) => {
          return (
            (task.id === undefined && new Date(task.end_date) < today) ||
            task.id !== undefined
          );
        });
      }
      if (
        appliedFilters.completion_status === TaskStoreCompletionStatus.Overdue
      ) {
        eventData = data.filter((task: CalendarEvent) => {
          return (
            task.id !== undefined &&
            isDateBeforeToday(
              task.store_scheduled_end_date
                ? task.store_scheduled_end_date
                : task.end_date
            )
          );
        });
      }
    }

    setEvents(eventData);
  }, [data?.length, appliedFilters.completion_status]);

  useEffect(() => {
    if (data !== undefined) {
      setSearchBarData();
      setEventFilter();
    }
  }, [data?.length, appliedFilters.completion_status]);

  useEffect(() => {
    if (!drawerProps.isDrawerOpen) {
      setFireUseQuery({ triggerTime: new Date(Date.now()) });
    }
  }, [drawerProps.isDrawerOpen]);

  return (
    <>
      <FeedbackButton
        analyticsEvent={analyticsEventConstants.FEEDBACK_VIEW_CALENDAR}
      />
      <div className="ScreenContainer">
        {!drawerProps.isDrawerOpen && userPermissions?.createNewTask ? (
          <IconButtonWithLabel
            icon={IconEnum.OutlinedPlus}
            label={'ADD TASK'}
            buttonLabel={'ADD TASK'}
            onClick={openCreate}
            backgroundColor={
              theme.newColorPalette.backgroundAddTaskIconDarkBlack[
                userPreference.colorMode
              ]
            }
            color={theme.colorPalette.plainWhite}
            passedStyle={calendarStyles.addTaskIconButton}
          />
        ) : null}
        {window.innerWidth > 421 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse' as 'row-reverse',
              width: '100%',
            }}
          >
            <PrintButton
              analyticsEvent={analyticsEventConstants.PRINT_VIEW_CALENDAR}
            />
          </div>
        )}
        {events !== undefined && (
          <Calendar findFullEventToOpen={findFullEventToOpen} events={events} />
        )}
      </div>
    </>
  );
};
