import { env } from 'src/envConfig';
import {getUserInfoObj, GAUserInfoObj} from "../okta/getUserInfoObj";
import ReactGA from "react-ga4";

export const GA_TRACKING_ID = env.REACT_APP_GA_TRACKING_ID;

export const analyticsEventConstants = {
  /* istanbul ignore next */
  CREATE_TASK: {
    action: 'CREATE_TASK',
    category: 'CREATE',
    label: 'Create Task',
  },
  SUBMIT_TASK: {
    action: 'SUBMIT_TASK',
    category: 'SUBMIT',
    label: 'Submit Task',
  },
  VIEW_TASK: {
    action: 'VIEW_TASK',
    category: 'VIEW',
    label: 'View Task',
  },
  EDIT_TASK: {
    action: 'EDIT_TASK',
    category: 'EDIT',
    label: 'Edit Task',
  },
  DELETE_TASK: {
    action: 'DELETE_TASK',
    category: 'DELETE',
    label: 'Delete Task',
  },
  MONTH_VIEW: {
    action: 'MONTH_VIEW',
    category: 'CALENDAR VIEW',
    label: 'Month View',
  },
  WEEK_VIEW: {
    action: 'WEEK_VIEW',
    category: 'CALENDAR VIEW',
    label: 'Week View',
  },
  DAY_VIEW: {
    action: 'DAY_VIEW',
    category: 'CALENDAR VIEW',
    label: 'Day View',
  },
  CALENDAR_TODAY: {
    action: 'CALENDAR_TODAY',
    category: 'CALENDAR VIEW NAVIGATE',
    label: 'Calendar Today',
  },
  CALENDAR_FORWARD: {
    action: 'CALENDAR_FORWARD',
    category: 'CALENDAR VIEW NAVIGATE',
    label: 'Calendar Forward',
  },
  CALENDAR_BACK: {
    action: 'CALENDAR_BACK',
    category: 'CALENDAR VIEW NAVIGATE',
    label: 'Calendar Back',
  },
  APPROVE_TASK: {
    action: 'APPROVE_TASK',
    category: 'TASK APPROVE/RETURN',
    label: 'Approve Task',
  },
  RETURN_TASK: {
    action: 'RETURN_TASK',
    category: 'TASK APPROVE/RETURN',
    label: 'Return Task',
  },
  VIEW_ATTACHMENT: {
    action: 'VIEW_ATTACHMENT',
    category: 'ATTACHMENTS DOWNLOAD',
    label: 'Download Attachment',
  },
  PRINT_VIEW_CALENDAR: {
    action: 'PRINT_VIEW',
    category: 'PRINT',
    label: 'Print Calendar',
  },
  EASY_PRINT: {
    action: 'EASY_PRINT',
    category: 'PRINT',
    label: 'Easy Print Month Calendar',
  },
  PRINT_VIEW_APPROVAL: {
    action: 'PRINT_VIEW',
    category: 'PRINT',
    label: 'Print Review/Approvals',
  },
  FEEDBACK_VIEW_CALENDAR: {
    action: 'FEEDBACK_VIEW',
    category: 'FEEDBACK',
    label: 'Feedback Calendar',
  },
  FEEDBACK_VIEW_APPROVAL: {
    action: 'FEEDBACK_VIEW',
    category: 'FEEDBACK',
    label: 'Feedback Review/Approvals',
  },
  APPLY_FILTERS: {
    action: 'APPLY_FILTERS',
    category: 'FILTER',
    label: 'Apply Filters Button',
  },
  TOGGLE_COLOR_MODE: {
    action: 'TOGGLE_COLOR_MODE',
    category: 'COLORMODE',
    label: 'Toggle Color Mode Button',
  },
  TOGGLE_VIEW_TYPE: {
    action: 'TOGGLE_VIEW_TYPE',
    category: 'VIEWTYPE',
    label: 'Toggle View Type Button',
  },
  TOGGLE_VIEW_ROWNUMBER: {
    action: 'TOGGLE_VIEW_ROWNUMBER',
    category: 'VIEW_ROWNUMBER',
    label: 'Toggle View Rownumber Button',
  },
  SEARCH_FOR_TASK_BY_TITLE: {
    action: 'SEARCH_FOR_TASK_BY_TITLE',
    category: 'SEARCH',
    label: 'Search for task by title',
  },
  PRINT_TASK: {
    action: 'PRINT_TASK',
    category: 'PRINT',
    label: 'Print Task',
  },
  TASK_MARKED_COMPLETE: {
    action: 'TASK_MARKED_COMPLETE',
    category: 'TASK COMPLETION',
    label: 'Tasks Completed',
  },
  REOPEN_COMPLETED_TASK: {
    action: 'REOPEN_COMPLETED_TASK',
    category: 'TASK COMPLETION',
    label: 'Reopen Completed Task',
  },
  DUPLICATE_TASK: {
    action: 'DUPLICATE_TASK',
    category: 'CREATE',
    label: 'Task Duplicated',
  },
  KEY_CONTACTS: {
    action: 'KEY_CONTACTS',
    category: 'KEY CONTACTS',
    label: 'Key Contacts Page View',
  },
  MOBILE_METRICS: {
    action: 'MOBILE_METRICS',
    category: 'MOBILE METRICS',
    label: 'Mobile Metrics Page View',
  },
  ADD_COMMENT: {
    action: 'ADD_COMMENT',
    category: 'COMMENT',
    label: 'Add Task Comment',
  },
  VIEW_COMMENT: {
    action: 'VIEW_COMMENT',
    category: 'COMMENT',
    label: 'View Comments Store Level',
  },
  VIEW_COMMENTS_FROM_DISTRICT_DROPDOWN: {
    action: 'VIEW_COMMENT',
    category: 'COMMENT',
    label: 'View Comments District Level',
  },
  VIEW_COMMENTS_FROM_REGION_DROPDOWN: {
    action: 'VIEW_COMMENT',
    category: 'COMMENT',
    label: 'View Comments Region Level',
  },
  VIEW_COMMENTS_FROM_TERRITORY_DROPDOWN: {
    action: 'VIEW_COMMENT',
    category: 'COMMENT',
    label: 'View Comments Territory Level',
  },
  EDIT_COMMENT: {
    action: 'EDIT_COMMENT',
    category: 'COMMENT',
    label: 'Edit Task Comment',
  },
  DELETE_COMMENT: {
    action: 'DELETE_COMMENT',
    category: 'COMMENT',
    label: 'Delete Task Comment',
  },
  ACTIVITY_HISTORY: {
    action: 'ACTIVITY_HISTORY',
    category: 'ACTIVITY',
    label: 'View Task Details Activity History',
  },
  ACTIVITY_HISTORY_APPROVAL: {
    action: 'ACTIVITY_HISTORY',
    category: 'ACTIVITY',
    label: 'Review Approval Activity History',
  },
  SELECT_TASK_SCHEDULED_DATE: {
    action: 'SELECT_TASK_SCHEDULED_DATE',
    category: 'TASK SCHEDULE',
    label: 'Task Scheduled Date Picked',
  },
  RESET_TASK_SCHEDULED_DATE: {
    action: 'RESET_TASK_SCHEDULED_DATE',
    category: 'TASK SCHEDULE',
    label: 'Task Scheduled Date Reset',
  },
  CHANGE_TASK_SCHEDULED_DATE: {
    action: 'CHANGE_TASK_SCHEDULED_DATE',
    category: 'TASK SCHEDULE',
    label: 'Task Scheduled Date Changed',
  },
  NAVIGATION_LIST_VIEW: {
    action: 'TASK_LIST_VIEW',
    category: 'LIST_VIEW',
    label: 'View Task List',
  },
  NOTIFICATION_VIEW: {
    action: 'NEW_FEATURES',
    category: 'NOTIFICATIONS',
    label: 'New Features Notification Bell',
  },
  SAVED_FILTERS: {
    action: 'SAVED_FILTERS',
    category: 'CALENDAR FILTERS',
    label: 'Save Filters',
  },
  LOGOUT: {
    action: 'LOGOUT',
    category: 'AUTHENTICATION',
    label: 'User Logout',
  },
  SELECT_PROFILE: {
    action: 'SELECT_PROFILE',
    category: 'AUTHENTICATION',
    label: 'Select Profile',
  },
  ALL_STORES_ASSIGNED_LIST: {
    action: 'ALL_STORES_ASSIGNED_LIST',
    category: 'LIST_VIEW',
    label: 'Export All Stores Assigned List',
  },
  TASK_COMPLETION_STATUS_LIST: {
    action: 'TASK_COMPLETION_STATUS_LIST',
    category: 'LIST_VIEW',
    label: 'Export Task Completion Status List',
  },
  TASK_COMPLETION_STATUS_LIST_DRAWER: {
    action: 'TASK_COMPLETION_STATUS_LIST',
    category: 'CALENDAR VIEW',
    label: 'Export Task Completion Status List in Drawer',
  },
  PRINT_TASK_LIST_VIEW: {
    action: 'PRINT_VIEW',
    category: 'PRINT',
    label: 'Print Task List',
  },
  NAVIGATION_COMPLETION_REPORTING_VIEW: {
    action: 'COMPLETION_REPORT_VIEW',
    category: 'COMPLETION_DASHBOARD',
    label: 'View Completion Report',
  },
  COMPLETION_DASHBOARD_DATA: {
    action: 'EXPORT_COMPLETION_DATA_CSV',
    category: 'COMPLETION_DASHBOARD',
    label: 'Export Completion Data as CSV',
  },
  COMPLETION_DASHBOARD_DATA_LIST: {
    action: 'EXPORT_COMPLETION_DATA_LIST_CSV',
    category: 'COMPLETION_DASHBOARD',
    label: 'Export Completion Data List as CSV',
  },
  COMPLETION_DASHBOARD_REPORTING_DATA_LIST: {
    action: 'COMPLETION_REPORT_CSV',
    category: 'COMPLETION_DASHBOARD',
    label: 'Download Completion Report',
  },
  NAVIGATION_MERCHANDISING_VIEW: {
    action: 'MERCHANDISING_VISUAL_MAIN_MENU',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'View Merchandising Visual Main Page',
  },
  MERCHANDISING_FLIPPINGBOOK: {
    action: 'MERCHANDISING_VISUAL_MERCHANDISING',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'View FlipBook',
  },
  MERCHANDISING_GOOGLESITE: {
    action: 'MERCHANDISING_VISUAL_MANNEQUINS',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'View Mannequin Direction',
  },
  MERCHANDISING_PDF: {
    action: 'MERCHANDISING_VISUAL_PDF',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'View Merchandising PDF',
  },
  MERCHANDISING_VIDEO: {
    action: 'MERCHANDISING_VISUAL_VIDEO',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'View Merch Training Video',
  },
  MERCHANDISING_OPEN_ADD_EDIT_MENU: {
    action: 'ENTER_ADD_EDIT_MENU',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'Enter Add-Edit Menu Digital Merch',
  },
  MERCHANDISING_SAVE_FROM_ADD: {
    action: 'ADD_MENU',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'Add New Digital Merch',
  },
  MERCHANDISING_SAVE_FROM_EDIT: {
    action: 'EDIT_MENU',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'Edit Digital Merch',
  },
  MERCHANDISING_DELETE: {
    action: 'DELETE_MENU',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'Delete Digital Merch',
  },
  MERCHANDISING_EXIT_MENU: {
    action: 'EXIT_MENU',
    category: 'MERCHANDISING_VISUAL_DIGITAL_COMMUNICATIONS',
    label: 'Exit Add-Edit Menu Digital Merch',
  },
  TASK_READ_RECEIPT: {
    action: 'EXPORT_TASK_READ_RECEIPT',
    category: 'EXPORTED_TASK_READ_RECEIPT_COMMUNICATIONS',
    label: 'Export Read Receipt',
  },
  SAFETY_VIDEO: {
    action: 'SAFETY_VIDEO',
    category: 'SAFETY_VIDEO',
    label: 'Safety Risk Compliance Video',
  },
};

export const sendAnalyticsPageview = (url: any, pageTitle?: string) => {
  try {
    ReactGA.send({
      send_to: `${GA_TRACKING_ID}`,
      hitType: "pageview",
      page: url,
      title: pageTitle,
    });
  } catch (error) {
    console.error('Error from the trackerPageView => ', error);
  }
};

type trackObjType = {
  action: string;
  category: string;
  label: string;
  storeNumber?: string;
  territoryNumber?: string;
  regionNumber?: string;
  districtNumber?: string;
  jobTitle?: string;
  employeeId?: string;
  sitelineGroup?: string;
  attachmentType?: string;
  searchTaskId?: string;
  state?: string;
};

export const event = (trackObj: trackObjType) => {
  const {
    action,
    category,
    label,
    storeNumber,
    territoryNumber,
    regionNumber,
    districtNumber,
    jobTitle,
    employeeId,
    sitelineGroup,
    attachmentType,
    searchTaskId,
    state,
  } = trackObj;
  try {
    if (window.gtag) {
      window.gtag('event', action, {
        send_to: `${GA_TRACKING_ID}`,
        page_path: window.location.pathname + window.location.search,
        event_category: category,
        event_label: label,
        store_number: storeNumber,
        territory_number: territoryNumber,
        region_number: regionNumber,
        district_number: districtNumber,
        job_title: jobTitle,
        employee_id: employeeId,
        siteline_group: sitelineGroup,
        attachment_type: attachmentType,
        searchTaskId,
        state: state !== undefined ? state : 'N/A',
        transport: "beacon",
      });
    }
  } catch (error) {
    console.error('Error from the trackerPageView => ', error);
  }
};

export type AnalyticEventType = {
  action: string;
  category: string;
  label: string;
  attachmentType?: string;
  searchTaskId?: string;
  state?: string;
};

export const sendAnalyticsEvent = ({
  action,
  category,
  label,
  attachmentType,
  searchTaskId,
  state,
}: AnalyticEventType) => {
  const userInfoObj: GAUserInfoObj | undefined = getUserInfoObj()
  // const getUserInfoObj = localStorage.getItem('userInfoObj');
  let sentUserInfoObj: GAUserInfoObj | undefined;

  if (userInfoObj) {
    sentUserInfoObj = userInfoObj
  }

  if(sentUserInfoObj !== undefined) {
    const trackObj: trackObjType = {
      action,
      category,
      label,
      state: state !== undefined ? state : 'N/A',
      ...sentUserInfoObj,
    };
    if (attachmentType) {
      trackObj.attachmentType = attachmentType;
    }
    if (searchTaskId) {
      trackObj.searchTaskId = searchTaskId;
    }

    event({ ...trackObj });
  } else {
    const errorObj: Error = {
      name: 'GA: Undefined User Obj',
      message: 'GA failed to send analytics event. Undefined User Info.'
    }
    console.error(errorObj)
  }

};
