import axios, { AxiosResponse } from 'axios';
import { env } from '../envConfig';
import { apiRequestConfig } from './apiV1Task';
import snackbarPropsStore from '../stores/snackbarPropsStore';
import { getRequest } from './apiUtils';

const setSnackbarProps = snackbarPropsStore.getState().setSnackbarProps;

export const getNewFeatures = async () => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/newFeatures`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};

export type NewFeaturesObj = {
  title: string;
  creation_time?: Date;
  attachments_id: string;
  file_information: Object;
};

export const createNewFeature = async (values: NewFeaturesObj) => {
  const now = new Date();
  setSnackbarProps({ actionStatus: 'loading', isSnackbarVisible: true });
  try {
    const response: AxiosResponse = await axios.post(
      `${env.REACT_APP_BACKEND_URL}/api/v1/newFeatures`,
      {
        ...values,
        creation_time: now.toUTCString(),
      },
      await apiRequestConfig()
    );
    setSnackbarProps({ actionStatus: 'success', isSnackbarVisible: true });
    return response.data;
  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const createNewFeaturesViewedEmployee = async (feature_id: string) => {
  try {
    const response: AxiosResponse = await axios.post(
      `${env.REACT_APP_BACKEND_URL}/api/v1/newFeatures/viewedByEmployee/`,
      {
        feature_id,
      },
      await apiRequestConfig()
    );

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getNewFeaturesViewedEmployee = async () => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/newFeatures/viewedByEmployee/`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};
