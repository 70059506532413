import axios, { AxiosResponse } from 'axios';
import { TaskApiResponse } from '../models/apiV1TaskResponses';
import { CalendarEvent } from '../components/types/Calendar/ICalendarIndex';
import { TaskStatus } from '../models/TaskStatus';
import { TaskToCalendarEvent } from '../utils/TaskAdapter';
import { env } from 'src/envConfig';
import { convertDateToUTC } from '../utils/dateUtils';
import { TaskStoreCompletionStatus } from '../models/TaskStoreCompletionStatus';
import snackbarPropsStore from '../stores/snackbarPropsStore';
import { getRequest } from './apiUtils';
import { v4 as uuidv4 } from 'uuid';
import {getOktaAuth} from "../okta/oktaApiUtils";

export const getOktaAccessToken = async () => {
    return await getOktaAuth().getOrRenewAccessToken();
};

export const apiRequestConfig = async () => {
  const messageId = uuidv4();
  const correlationId = uuidv4();
  return {
    headers: {
      Authorization: 'Bearer ' + await getOktaAccessToken(),
      'Message-ID': messageId,
      'Correlation-ID': correlationId,
    },
  };
};

const setSnackbarProps = snackbarPropsStore.getState().setSnackbarProps;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error({ error });
    return Promise.reject(error);
  }
);

// todo - filterTasksAndPromotions change to default API

export type CreateCommentValues = {
  task_id: string;
  store_number: string;
  content: string;
  emp_id: string;
  emp_first_name: string;
  emp_last_name: string;
};

export const createComment = async (
  values: CreateCommentValues
): Promise<object | null> => {
  const now = new Date();
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskComments`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.post(url,
          {
            ...values,
            date_created: now.toUTCString(),
          },
          await apiRequestConfig()
      );
      setSnackbarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      return response.data;
    } else {
      console.log('You dont have permission to POST from that url')
      return null;
    }
  } catch (error: any) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const createTask = async (
  values: TaskApiResponse,
  setSnackBarProps: Function,
  resetToDefault: Function
): Promise<object | null> => {
  const {
    title,
    task_type,
    department,
    start_date,
    end_date,
    store_number,
    require_completion,
    schedule_date,
    funded,

    labor_hours,
    task_description,
    leadership_calendar,
    year_at_a_glance,
    attachments_id,
    attachment_information,
    author_details,
  } = values;
  const requestBody = {
    title,
    task_type,
    department:
      task_type === 'Merchandising' && department ? department : undefined,
    store_number,
    start_date: convertDateToUTC(start_date),
    end_date: convertDateToUTC(end_date),
    require_completion,
    schedule_date,
    funded,
    labor_hours: funded ? labor_hours : undefined,
    leadership_calendar,
    year_at_a_glance,
    task_description,
    attachments_id,
    attachment_information: JSON.stringify(attachment_information),
    author_details,
  };

  setSnackBarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });

  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.post(url,
          {
            ...requestBody,
          },
          await apiRequestConfig()
      );
      setSnackBarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      resetToDefault();
      return response.data;
    } else {
      console.log('You dont have permission to POST from that url')
      return null;
    }
  } catch (error) {
    setSnackBarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config?.headers['Correlation-ID'],
    });
    return null;
  }
};

export const createTaskFromDistroList = async (
  values: any,
  setSnackBarProps: Function,
  resetToDefault: Function
): Promise<CalendarEvent | null> => {
  const {
    title,
    task_type,
    department,
    require_completion,
    task_description,
    leadership_calendar,
    year_at_a_glance,
    store_list_distro_file,
    attachments_id,
    attachment_information,
    author_details,
    schedule_date
  } = values;
  const requestBody = {
    title,
    task_type,
    department:
      task_type === 'Merchandising' && department ? department : undefined,
    require_completion,
    leadership_calendar,
    year_at_a_glance,
    task_description,
    store_list_distro_file,
    attachments_id,
    schedule_date,
    attachment_information: JSON.stringify(attachment_information),
    author_details: JSON.stringify(author_details),
  };

  setSnackBarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });

  try {
    const formData = new FormData();
    for (const key in requestBody) {
      if (Array.isArray(requestBody[key])) {
        const value = requestBody[key];
        value.forEach((val: string) => {
          formData.append(`${key}[]`, val);
        });
      } else {
        formData.append(key, requestBody[key]);
      }
    }

    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/distroFileUpload`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.post(url,
          formData,
          await apiRequestConfig()
      );
      setSnackBarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      resetToDefault();
      return response.data;
    } else {
      console.log('You dont have permission to POST from that url')
      return null;
    }
  } catch (error) {
    setSnackBarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const deleteTaskById = async (id: string): Promise<string | null> => {
  const response: AxiosResponse = await axios.delete(
    `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${id}`,
    await apiRequestConfig()
  );
  return response.data;
};

export const updateTask = async (
  values: any,
  setSnackBarProps: Function,
  resetToDefault: Function
): Promise<CalendarEvent | null> => {
  const {
    id,
    title,
    task_type,
    task_status,
    department,
    start_date,
    end_date,
    schedule_date,
    store_number,
    require_completion,
    funded,
    labor_hours,
    task_description,
    leadership_calendar,
    year_at_a_glance,
    author_details,
    attachment_information,
    distro_file_information,
  } = values;

  const requestBody = {
    id,
    title,
    task_type,
    task_status,
    start_date: convertDateToUTC(start_date),
    end_date: convertDateToUTC(end_date),
    schedule_date,
    store_number,
    require_completion,
    funded,
    labor_hours: funded ? labor_hours : undefined,
    task_description,
    leadership_calendar,
    year_at_a_glance,
    department: department && task_type === 'Merchandising' ? department : null,
    author_details,
    attachment_information: JSON.stringify(attachment_information),
    distro_file_information,
  };

  setSnackBarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });

  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${requestBody.id}`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.put(url,
        requestBody,
          await apiRequestConfig()
      );
      setSnackBarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      resetToDefault();
      return response.data;
    } else {
      console.log('You dont have permission to PUT from that url')
      return null;
    }
  } catch (error) {
    setSnackBarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config?.headers['Correlation-ID'],
    });
    return null;
  }
};

export const updateTaskStatus = async (
  statusCode: TaskStatus,
  taskId?: string
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${taskId}/taskStatus`
  const whitelist = [url]
  if(whitelist.includes(url)) {
    const response: AxiosResponse = await axios.put(url,
        {
          task_status: statusCode,
        },
        await apiRequestConfig()
    );
    return response.data;
  } else {
    console.log('You dont have permission to PUT from that url')
    return null;
  }
};

export const getTasksForReviewAndApprovalScreen = async (
  taskStatus: TaskStatus
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskLists/reviewApproval?task_status=${taskStatus}`;
  return getRequest(url);
};

export const getTaskDescriptionByTaskId = async (
  task_id: string | undefined
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskDescription`;
  return getRequest(url);
};

export const getTaskStoreLevelDetails = async (
  task_id?: string,
  store_number?: string
) => {
  if (store_number) {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/storeLevelDetails/?store_number=${store_number}`;
    return getRequest(url);
  } else {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/storeLevelDetails`;
    return getRequest(url);
  }
};

export const getTaskLevelDetails = async (task_id: string | undefined) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}`;
  return getRequest(url);
};

export const getStoreLocationInformation = async () => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/storeLocations`;
  return getRequest(url);
};

export const getTasksWithAppliedFilters = async (queryString: string) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskLists/${queryString}`;
  const response = await getRequest(url);

  const data = response.map((task: TaskApiResponse) =>
    TaskToCalendarEvent(task)
  );

  return data;
};

export const getTaskAndPromotionsWithAppliedFilters = async (
  queryString: string
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskLists/withPromotions/${queryString}`;
  const response = await getRequest(url);

  const data = response.map((task: TaskApiResponse) =>
    TaskToCalendarEvent(task)
  );

  return data;
};

export const getStoreLocationInformationByRegionAndTaskID = async (
  location_number: number,
  id: string,
  location_type: 'district' | 'region' | 'territory'
) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${id}/newStoreNumbersByLocation?${location_type}_number=${location_number}`;
    return getRequest(url);
  } catch (e) {
    console.error({ e });
    return [];
  }
};

export const getTaskStoreCompletionStatus = async (
  id: string,
  store_number: string
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${id}/completionStatus/storeNumber/${store_number}`;
  return getRequest(url);
};

export const getRegionalTaskStoreCompletionStatus = async (
  id: string,
  locationFilter: { territory_number?: number; region_number?: number; district_number?: number }
) => {
  const key = Object.keys(locationFilter)[0];
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${id}/completionStatus/?${key}=${locationFilter[key]}`;
  return getRequest(url);
};

export const countNumberOfStoresForAllStoreTaskByTaskID = async (
  id: string
) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${id}/countOfStoresForAllStoreTasks`;
  return getRequest(url);
};

export const updateTaskStoreCompletionStatus = async (values: {
  id: string;
  store_number: string;
  completion_status: TaskStoreCompletionStatus;
}) => {
  const requestBody = {
    store_number: values.store_number,
    completion_status: values.completion_status,
  };
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${values.id}/completionStatus`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.put(
          url,
          requestBody,
          await apiRequestConfig()
      );
      setSnackbarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      return response.data;
    } else {
      console.log('You dont have permission to PUT from that url')
      return null;
    }
  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    console.error({ error });
    return null;
  }
};

export const deleteTaskCommentById = async (
  id: string
): Promise<string | null> => {
  const response: AxiosResponse = await axios.delete(
    `${env.REACT_APP_BACKEND_URL}/api/v1/taskComments/${id}`,
    await apiRequestConfig()
  );
  return response.data;
};

export const updateTaskCommentById = async (
  content: string,
  id: string
): Promise<string | null> => {
  const requestBody = {
    content,
  };
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskComments/${id}`
  const whitelist = [url]
  if(whitelist.includes(url)) {
    const response: AxiosResponse = await axios.put(url,
        requestBody,
        await apiRequestConfig()
    );
    return response.data;
  } else {
    console.log('You dont have permission to PUT from that url')
    return null;
  }
};

export const getStoreLevelCommentsByRegionAndTaskID = async (
  location_number: number,
  task_id: string,
  location_type: 'district' | 'region' | 'territory',
  show_type?: 'All'|'Only'
) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskComments/storeLevelCommentsByLocation?${location_type}_number=${location_number}&task_id=${task_id}&show_type=${show_type}`;
    return getRequest(url);
  } catch (e) {
    console.error({ e });
    return [];
  }
};

export type CreateEditNoteValues = {
  notes: string;
  emp_id: string;
  emp_first_name: string;
  emp_last_name: string;
};
export const createEditNote = async (
  task_id: string,
  values: CreateEditNoteValues
): Promise<object | null> => {
  const now = new Date();
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskEditNote`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.post(url,
          {
            ...values,
            date_edited: now.toUTCString(),
          },
          await apiRequestConfig()
      );
      setSnackbarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      return response.data;
    } else {
      console.log('You dont have permission to POST from that url')
      return null;
    }
  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const getEditNotesForTaskByTaskId = async (task_id: string) => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskEditNote`;
  return getRequest(url);
};

export type ScheduledDatesObj = {
  store_number: string;
  store_scheduled_start_date: Date;
  store_scheduled_end_date: Date;
};
export const createOrUpdateScheduledDates = async (
  task_id: string,
  values: ScheduledDatesObj
) => {
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskScheduledDates`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.put(
          url,
          { ...values },
          await apiRequestConfig()
      );
      setSnackbarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      return response.data;
    } else {
      console.log('You dont have permission to PUT from that url')
      return null;
    }

  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const getTaskStoreScheduledDates = async (
  task_id: string,
  store_number: string
) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskScheduledDates/?store_number=${store_number}`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};

export const deleteTaskStoreScheduledDates = async (
  task_id: string,
  store_number: string
) => {
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {

    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskScheduledDates/?store_number=${store_number}`
    const whitelist = [url]
    if(whitelist.includes(url)) {
      const response: AxiosResponse = await axios.delete( url,
          await apiRequestConfig()
      );
      setSnackbarProps({
        actionStatus: 'success',
        isSnackbarVisible: true,
        correlationId: undefined,
      });
      return response.data;
    } else {
      console.log('You dont have permission to DELETE from that url')
      return null;
    }
  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const getTaskStoreNumberCompletion = async (
  task_id: string,
  region_number: number | undefined,
  region_type: 'district' | 'region' | 'territory' | undefined
) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskStoreNumberCompletion/?${region_type}_number=${region_number}`;
    return getRequest(url);
  } catch (error) {
    return undefined;
  }
};

export const updateTaskViewedReadReceiptById = async (
  id: string
): Promise<string | null> => {
  const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${id}/taskReadReceipts`
  const whitelist = [url]
  if(whitelist.includes(url)) {
    const response: AxiosResponse = await axios.put(url,
      undefined,
        await apiRequestConfig()
    );
    return response.data;
  } else {
    console.log('You dont have permission to PUT from that url')
    return null;
  }
};

export const getTaskViewedReadReceiptsById = async (task_id: string) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/taskReadReceipts/viewedTaskReadReceipts`;
    return getRequest(url);
  } catch (error) {
    return undefined;
  }
};
