import React from 'react';
import { IconButton } from '../../Buttons/IconButton';
import { createOrUpdateEmployeePreference } from '../../../api/apiEmployee';
import { IconEnum } from 'src/components/Chip/chipUtils/ChipEnums';
import { viewTypeStyles } from './viewTypeStyles';
import { theme } from 'src/assets/siteLineTheme';
import ViewRowNumber from './ViewRowNumber';
import userPreferenceStore, {
  UserPreferenceStoreState,
  ViewTypeEnum,
} from '../../../stores/userPreferenceStore';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from 'src/analytics/analytics';

const ViewType: React.FC = () => {
  const { userPreference, setUserPreference } = userPreferenceStore(
    (state: {
      userPreference: UserPreferenceStoreState;
      setUserPreference: any;
    }) => ({
      userPreference: state.userPreference,
      setUserPreference: state.setUserPreference,
    })
  );

  const setToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserPreference({
      ...userPreference,
      viewType: ViewTypeEnum[value],
    });
    sendAnalyticsEvent(analyticsEventConstants.TOGGLE_VIEW_TYPE);
    await createOrUpdateEmployeePreference({
      view_row_number: userPreference.viewRowNumber,
      color_mode_type: userPreference.colorMode,
      view_type: ViewTypeEnum[value],
    });
  };

  const viewTypeClasses = viewTypeStyles();

  return (
    <>
      <div style={viewTypeClasses.viewTypeContainer}>
        <div style={viewTypeClasses.viewTypeTitle}>View Type</div>
        <div style={viewTypeClasses.viewTypeRadioContainer}>
          <div>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
              aria-label={'Calendar View Radio'}
              type={'radio'}
              name={'viewType'}
              value={ViewTypeEnum.Calendar}
              checked={userPreference.viewType === ViewTypeEnum.Calendar}
              onChange={setToggle}
            />
            <IconButton
              label={'Calendar View'}
              icon={IconEnum.Calendar}
              onClick={() => null}
              color={
                theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
              }
              size={'0.9em'}
              margin={'0 0.2em'}
            />
            <div style={viewTypeClasses.viewTypeText}> Calendar </div>
          </div>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
              aria-label={'List View Radio'}
              type={'radio'}
              name={'viewType'}
              value={ViewTypeEnum.List}
              checked={userPreference.viewType === ViewTypeEnum.List}
              onChange={setToggle}
            />
            <IconButton
              label={'List View'}
              icon={IconEnum.List}
              onClick={() => null}
              color={
                theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
              }
              size={'0.9em'}
              margin={'0 0.2em'}
            />
            <div style={viewTypeClasses.viewTypeText}> List </div>
          </div>
          </div>
          <div>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
              aria-label={'Merchandising View Radio'}
              type={'radio'}
              name={'viewType'}
              value={ViewTypeEnum.Merchandising}
              checked={userPreference.viewType === ViewTypeEnum.Merchandising}
              onChange={setToggle}
            />
            <IconButton
              label={'Merchandising View'}
              icon={IconEnum.Merchandising}
              onClick={() => null}
              color={
                theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
              }
              size={'1.1em'}
              margin={'0 0.2em'}
            />
            <div style={viewTypeClasses.viewTypeText}> Merchandising </div>
          </div>
          <div style={viewTypeClasses.viewTypeRadio}>
            <input
                aria-label={'Safety View Radio'}
                type={'radio'}
                name={'viewType'}
                value={ViewTypeEnum.Safety}
                checked={userPreference.viewType === ViewTypeEnum.Safety}
                onChange={setToggle}
            />
            <IconButton
                label={'Safety View'}
                icon={IconEnum.Safety}
                onClick={() => null}
                color={
                  theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
                }
                size={'1.1em'}
                margin={'0 0.2em'}
            />
            <div style={viewTypeClasses.viewTypeText}> Safety </div>
          </div>
          </div>
        </div>
        <div style={viewTypeClasses.viewTypeInformText}>
          {' '}
          * You will meet {userPreference.viewType} View when you login next
          time.{' '}
        </div>
      </div>
      <ViewRowNumber />
    </>
  );
};

export default ViewType;
