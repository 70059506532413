import { env } from '../envConfig';
import { getRequest } from './apiUtils';

export const getTaskCompletionData = async (queryString: string) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskDashboard/${queryString}`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};

export const getTaskCompletionList = async (queryString: string) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskDashboard/List/${queryString}`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};

export const getTaskCompletionListCsv = async (
  task_id: string,
  queryString: string
) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskDashboard/ListCsv/${queryString}&task_id=${task_id}`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};

export const getTaskCompletionReportingListCsv = async (
    queryString: string
) => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/taskDashboard/reporting/${queryString}`;
    return getRequest(url);
  } catch (error) {
    return null;
  }
};
