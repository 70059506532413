import './App.css';
import {Route, Switch, useHistory} from 'react-router-dom';
import {LoginCallback, SecureRoute, Security, useOktaAuth,} from '@okta/okta-react';
import {toRelativeUrl} from '@okta/okta-auth-js';
import UserInfo from './UserInfo';
import ReviewApprovalScreen from './screens/ReviewApprovalScreen';
import AppState from './context/AppState';
import EasyPrintScreen from './screens/EasyPrintScreen';
import MobileMetrics from './screens/MobileMetrics';
import TaskListViewScreen from './screens/TaskListViewScreen';
import DigitalMerchandisingScreen from './screens/DigitalMerchandisingScreen';
import NewFeaturesCreate from './components/NewFeatures/NewFeaturesCreate';
import TaskCalendarScreen from './screens/TaskCalendarScreen';
import {getEmployeePreference} from './api/apiEmployee';
import {ViewTypeEnum} from './stores/userPreferenceStore';
import CompletionDashboardScreen from './screens/CompletionDashboardScreen';
import PageNotFound from './components/PageError/PageNotFound';
import PageAPIError from './components/PageError/PageAPIError';
import ErrorBoundary from './utils/ErrorBoundary';
import SafetyRiskComplianceScreen from "./screens/SafetyRiskComplianceScreen";
import {getOktaAuth} from "./okta/oktaApiUtils";
import PageTracker from "./analytics/PageViewTrack";

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    let relativeUrl =
        window.innerWidth > 420 ? originalUri || '/' : '/task-list-view';


    // Merchandising direct Url
    if (originalUri.includes('/digital-merchandising/')) {
      relativeUrl = originalUri || '/';
    } else {
      const employeePreference = await getEmployeePreference();
      if (
          employeePreference !== undefined &&
          employeePreference.view_type !== undefined &&
          employeePreference.view_type !== null
      ) {
        relativeUrl =
            employeePreference.view_type === ViewTypeEnum.List
                ? '/task-list-view'
                : employeePreference.view_type === ViewTypeEnum.Merchandising
                    ? '/digital-merchandising'
                    : employeePreference.view_type === ViewTypeEnum.Safety
                        ? '/safety-risk-compliance'
                        : originalUri || '/';
      }
    }
    history.replace(toRelativeUrl(relativeUrl, window.location.origin));
  };

  const callbackComponent = () => {
    const { oktaAuth } = useOktaAuth();
    return (
        <LoginCallback
            errorComponent={() => {
              const id = setTimeout(() => {
                localStorage.clear();
                window.location.href = '/';
              }, 2000);
              oktaAuth.signOut().then(() => {
                clearTimeout(id);
              });
              return null;
            }}
        />
    );
  };

  return (
      <ErrorBoundary>
        <div className="App">
          <Security
              oktaAuth={getOktaAuth()}
              restoreOriginalUri={restoreOriginalUri}
          >
            <AppState>
              <Switch>
                <SecureRoute exact path="/" component={TaskCalendarScreen} />
                <SecureRoute
                    exact
                    path="/easy-print"
                    component={EasyPrintScreen}
                />
                <SecureRoute
                    exact
                    path="/mobile-metrics"
                    component={MobileMetrics}
                />
                <SecureRoute
                    exact
                    path="/completion-data-dashboard"
                    component={CompletionDashboardScreen}
                />
                <SecureRoute
                    exact
                    path="/task-approvals"
                    component={ReviewApprovalScreen}
                />
                <SecureRoute
                    exact
                    path="/task-list-view"
                    component={TaskListViewScreen}
                />
                <SecureRoute exact path="/user" component={UserInfo} />
                <SecureRoute
                    exact
                    path="/task-new-feature"
                    component={NewFeaturesCreate}
                />
                <SecureRoute
                    exact
                    path="/digital-merchandising"
                    component={DigitalMerchandisingScreen}
                />
                <SecureRoute
                    exact
                    path="/digital-merchandising/:subPath+"
                    component={DigitalMerchandisingScreen}
                />
                <SecureRoute
                    exact
                    path="/safety-risk-compliance"
                    component={SafetyRiskComplianceScreen}
                />
                <SecureRoute
                    exact
                    path="/safety-risk-compliance/:subPath+"
                    component={SafetyRiskComplianceScreen}
                />
                <SecureRoute
                    path="/error"
                    exact={true}
                    component={PageAPIError}
                />
                <Route path={'/implicit/callback'} component={callbackComponent} />
                <Route
                    path={'/ping'}
                    component={() => <p>App is up and running!!!</p>}
                />
                <SecureRoute path="*" exact={true} component={PageNotFound} />
              </Switch>
            </AppState>
            <PageTracker />
          </Security>
        </div>
      </ErrorBoundary>
  );
}

export default App;
