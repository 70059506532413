import React, { useEffect, useState } from 'react';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from '../../../assets/siteLineTheme';
import { IconButton } from '../../Buttons/IconButton';
import { useOktaAuth } from '@okta/okta-react';
import { logoutStyles } from './logoutStyles';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';

const Logout: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [isSignout, setIsSignout] = useState<boolean>(false);

  const logout = () => {
    localStorage.removeItem('okta-token-storage');
    setIsSignout(true);
    sendAnalyticsEvent(analyticsEventConstants.LOGOUT);
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated && isSignout) {
      const id = setTimeout(() => {
        localStorage.clear();
        window.location.href = '/';
      }, 2000);
      oktaAuth.signOut().then(() => {
        clearTimeout(id);
      });
    }
  }, [authState, oktaAuth, isSignout]);

  const LogoutClasses = logoutStyles();

  return (
    <div style={LogoutClasses.logoutContainer} onClick={() => logout()}>
      <IconButton
        label={'LogOut'}
        icon={IconEnum.SignOut}
        color={theme.colorPalette.textDark}
        size={'1.7em'}
        margin={'0 1em 0 0'}
        onClick={() => logout()}
      />
      <div style={LogoutClasses.logoutText}> Log Out </div>
    </div>
  );
};

export default Logout;
