import {getOktaAccessToken, getRegionalTaskStoreCompletionStatus,} from './apiV1Task';
import appliedFiltersStore from './../stores/appliedFiltersStore';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {v4 as uuidv4} from "uuid";

export const fetchRegionalTaskCompletionStatus = async (
  id: string | undefined
) => {
  if (!id) {
    return {};
  }
  const appliedFilters = appliedFiltersStore.getState().appliedFilters;
  let regionalTaskStoreCompletionStatusPromise = {};

  if (appliedFilters.district_number && !appliedFilters.store_number) {
    regionalTaskStoreCompletionStatusPromise =
      getRegionalTaskStoreCompletionStatus(id, {
        district_number: appliedFilters.district_number,
      });
  } else if (appliedFilters.region_number && !appliedFilters.store_number) {
    regionalTaskStoreCompletionStatusPromise =
        getRegionalTaskStoreCompletionStatus(id, {
          region_number: appliedFilters.region_number,
        });
  } else if (appliedFilters.territory_number && !appliedFilters.store_number) {
    regionalTaskStoreCompletionStatusPromise =
      getRegionalTaskStoreCompletionStatus(id, {
        territory_number: appliedFilters.territory_number,
      });
  }
  return regionalTaskStoreCompletionStatusPromise;
};

// todo - swap out for getRequest()
// export const getDataWithOktaTokens = async (url: string, passedValue?: any) => {
//   const whitelist = [url]
//   if(whitelist.includes(url)) {
//     const response: AxiosResponse = await axios.get(url, {
//       params: {
//         ...passedValue,
//       },
//       ...await apiRequestConfig(),
//     });
//     return response.data;
//   } else {
//     console.log('You dont have permission to GET from that url')
//   }
// };

axios.interceptors.request.use(
    (request) => {
      return request
    },
    (error) => {
      console.error({error});
      return Promise.reject(error);
    }
)

const getConfig = async () => {
  // If there is an issue getting the token, reload the page to force back to login page.
  const accessToken = await getOktaAccessToken()
      .catch(err => {
        window.location.reload();
        throw err;
      });
  const messageId = uuidv4();
  const correlationId = uuidv4();

  return {
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'Message-ID': messageId,
      'Correlation-ID': correlationId,
    },
    timeout: 60000
  };
}

// will add more parameter for passing parameter
// or errorMsg
export const getRequest = async (url: string,  passedValue?: any) => {
  const whitelist = [url]
  if(whitelist.includes(url)) {
    const response: AxiosResponse = await axios.get(url, {
      params: {
        ...passedValue,
      },
      ...await getConfig(),
    });
    return response.data;
  } else {
    console.log('You dont have permission to GET from that url')
  }

  await getConfig()
      .then(config => axios.get(url, {...config}))
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError | any) => {
        if (error.config && error.response) {
          // can use as a redirect to 400
          // window.location.href = window.location.origin + "/error/" + error.response.status
          console.error(error, error.config.headers['Correlation-ID'])
          throw new Error(error.config.headers['Correlation-ID'])
        }
      });
}