import React from 'react';
import { listViewWeekDayContentStyles } from './styles/listViewWeekDayContentStyles';
import BaseButton from '../../Buttons/BaseButton';
import { theme } from '../../../assets/siteLineTheme';
import dateSelectionForDisplayStore, {
  dateLabelGenerator,
  DateRangeTypeEnum,
  DateSelectionForDisplayStoreProps,
} from '../../../stores/dateSelectionForDisplayStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { removeTime } from '../../../utils/dateUtils';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';

type ListViewWeekDayContentProps = {
  day: string;
  date: number;
  month: number;
  year: number;
};

const ListViewWeekDayContent: React.FC<ListViewWeekDayContentProps> = (
  props
) => {
  const { day, date, month, year } = props;
  // const start = startOfWeek(new Date(selectedDisplayDate));
  const listViewWeekDayContentClasses = listViewWeekDayContentStyles();
  const { dateSelectionForDisplayProps, setDateSelectionForDisplayProps } =
    dateSelectionForDisplayStore(
      (state: {
        dateSelectionForDisplayProps: DateSelectionForDisplayStoreProps;
        setDateSelectionForDisplayProps: any;
      }) => ({
        dateSelectionForDisplayProps: state.dateSelectionForDisplayProps,
        setDateSelectionForDisplayProps: state.setDateSelectionForDisplayProps,
      })
    );

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );

  const isDateSelected = () => {
    if (dateSelectionForDisplayProps.dateRangeType === DateRangeTypeEnum.DAY) {
      return (
        dateSelectionForDisplayProps.selectedDisplayDate
          .getDate()
          .toString() === date.toString()
      );
    }
    return true;
  };

  const setNewDay = () => {
    /* istanbul ignore next */
    const newDateSelectionForDisplayProps = {
      dateRangeType: DateRangeTypeEnum.DAY,
      selectedDisplayDate: new Date(year, month, date),
      dateLabel: dateLabelGenerator({
        dateRangeTypeEnum: DateRangeTypeEnum.DAY,
        selectedDisplayDate: new Date(year, month, date),
      }),
    };
    setDateSelectionForDisplayProps(newDateSelectionForDisplayProps);
    setAppliedFilters({
      ...appliedFilters,
      current_date: removeTime(new Date(year, month, date)).toISOString(),
      view_type: DateRangeTypeEnum.DAY.toLowerCase(),
    });
  };

  return (
    <BaseButton
      onClick={() => setNewDay()}
      activeBackgroundColor={
        theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
      }
      passiveBackgroundColor={
        theme.newColorPalette.plainWhiteGrey[userPreference.colorMode]
      }
      activeTextColor={
        theme.newColorPalette.plainBlackWhite[userPreference.colorMode]
      }
      passiveTextColor={
        theme.newColorPalette.plainBlackWhite[userPreference.colorMode]
      }
      margin={'0 0.75em 0 0'}
      padding={window.innerWidth > 420 ? '0.7em' : '0.45em'}
      label={`${day} ${date}`}
      isSelected={isDateSelected()}
      changeColorWhenSelected={isDateSelected()}
    >
      <div style={listViewWeekDayContentClasses.container}>
        <div style={listViewWeekDayContentClasses.dayLabel}>
          {day} {date}
        </div>
      </div>
    </BaseButton>
  );
};

export default ListViewWeekDayContent;
