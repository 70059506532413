import ReviewAndApprovalTable from '../components/ReviewAndApprovalTable/ReviewAndApprovalTable';
import React, { useEffect, useState } from 'react';
import {
  getTaskDescriptionByTaskId,
  getTaskLevelDetails,
  getTasksForReviewAndApprovalScreen,
  getTaskStoreLevelDetails,
} from '../api/apiV1Task';
import { TaskStatus } from '../models/TaskStatus';
import { TaskApiResponse } from '../models/apiV1TaskResponses';
import PrintButton from '../components/Buttons/PrintButton';

import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../analytics/analytics';
import FeedbackButton from '../components/Buttons/FeedbackButton';
import ReviewApprovalToolbar from '../components/Toolbar/ReviewApprovalToolbar';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import searchBarPropsStore from '../stores/searchBarPropsStore';
import { SetState } from 'zustand';
import { format } from 'date-fns';
import drawerPropsStore from '../stores/drawerPropsStore';
import { DrawerContentType } from '../stores/models/DrawerProps';
import { fetchRegionalTaskCompletionStatus } from 'src/api/apiUtils';
import { reviewApprovalAndCalendarScreenStyles } from './styles/reviewApprovalAndCalendarScreenStyles';
import IconButtonWithLabel from '../components/Buttons/IconButtonWithLabel';
import { IconEnum } from '../components/Chip/chipUtils/ChipEnums';
import { theme } from '../assets/siteLineTheme';
import {useHistory} from "react-router-dom";
const ReviewApprovalScreen: React.FC = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const reviewApprovalStyles = reviewApprovalAndCalendarScreenStyles({
    colorMode: userPreference.colorMode,
  });
  const [events, setEvents] = useState<TaskApiResponse[]>([]);
  const [eventFilterStatus, setEventFilterStatus] = useState('Submitted');
  const { setSearchBarProps } = searchBarPropsStore(
    (state: { setSearchBarProps: SetState<any> }) => ({
      setSearchBarProps: state.setSearchBarProps,
    })
  );
  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const convertEventsToDropdownOptions = (events: any) => {
    return (
      events &&
      events.map((task: any) => {
        return {
          title: task.title,
          task_type: task.task_type,
          dateRange: `Due ${format(new Date(task.end_date), 'MM/dd/yyyy')}`,
          identifier: task.id,
          optionType: 'task',
        };
      })
    );
  };

  const refreshTasksOnTable = async () => {
    const events = await getTasksForReviewAndApprovalScreen(
      TaskStatus[eventFilterStatus]
    );
    events && setEvents(events);
  };

  // ToDo refresh with new values when updating task in review and approval
  //   const events = await getTasksForReviewAndApprovalScreen(
  //       TaskStatus[eventFilterStatus]
  //   );
  //   events && setEvents(events);

  // passes into autocomplete dropdown
  const handleTaskClick = async (taskID: string, title: string) => {
    sendAnalyticsEvent(analyticsEventConstants.VIEW_TASK);
    const taskLevelDetails = await getTaskLevelDetails(taskID);
    const taskStoreLevelDetails = await getTaskStoreLevelDetails(taskID);
    const taskDescription = await getTaskDescriptionByTaskId(taskID);
    const taskCompletionStatus = await fetchRegionalTaskCompletionStatus(
      taskID
    );

    const taskEvent = {
      task_description: taskDescription,
      ...taskStoreLevelDetails,
      ...taskLevelDetails,
      ...taskCompletionStatus,
      distro_file_information: taskLevelDetails?.distro_file_information
        ? [taskLevelDetails?.distro_file_information]
        : [],
      store_number: taskLevelDetails?.distro_file_information
        ? undefined
        : taskStoreLevelDetails.store_number,
    };

    // const calendarEvent: CalendarEvent = TaskToCalendarEvent(taskEvent);
    setDrawerProps({
      isDrawerOpen: true,
      drawerContentType: DrawerContentType.TaskForm,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        viewEvent: taskEvent,
        formMode: 'approve',
      },
    });
  };

  const history = useHistory();

  useEffect(() => {
    if (drawerProps.isDrawerOpen === false) {
      getTasksForReviewAndApprovalScreen(TaskStatus[eventFilterStatus])
        .then((events: TaskApiResponse[] | null) => {
          if (events) {
            setEvents(events);
            setSearchBarProps({
              options: convertEventsToDropdownOptions(events),
              clickHandler: handleTaskClick,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          history.push('/error');
        });
    }
    return function cleanup() {};
  }, [eventFilterStatus, drawerProps.isDrawerOpen]);

  const handleAddTaskButtonClick = () => {
    sendAnalyticsEvent(analyticsEventConstants.CREATE_TASK);
    setDrawerProps({
      drawerContentType: DrawerContentType.TaskForm,
      isDrawerOpen: true,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        openEdit: handleEditButtonClick,
        formMode: 'create',
      },
    });
  };

  const handleEditButtonClick = () => {
    // move to Confirm Edit Notes
    // sendAnalyticsEvent(analyticsEventConstants.EDIT_TASK);
    setDrawerProps({
      ...drawerProps,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        formMode: 'edit',
      },
    });
  };

  return (
    <>
      <FeedbackButton
        analyticsEvent={analyticsEventConstants.FEEDBACK_VIEW_CALENDAR}
      />
      <div className="ScreenContainer">
        <div style={reviewApprovalStyles.printButtonRow}>
          {window.innerWidth > 420 && (
            <PrintButton
              analyticsEvent={analyticsEventConstants.PRINT_VIEW_APPROVAL}
            />
          )}
        </div>
        <ReviewApprovalToolbar
          colorMode={userPreference.colorMode}
          eventFilterStatus={eventFilterStatus}
          setEventFilterStatus={setEventFilterStatus}
        />

        {events.length > 0 && (
          <ReviewAndApprovalTable
            events={events}
            eventFilterStatus={TaskStatus[eventFilterStatus]}
            colorMode={userPreference.colorMode}
            refreshTasksOnTable={refreshTasksOnTable}
            rowNumber={userPreference.viewRowNumber}
          />
        )}

        {!drawerProps.isDrawerOpen && (
          <IconButtonWithLabel
            icon={IconEnum.OutlinedPlus}
            label={'Add Task'}
            buttonLabel={'ADD TASK'}
            onClick={handleAddTaskButtonClick}
            backgroundColor={
              theme.newColorPalette.backgroundAddTaskIconDarkBlack[
                userPreference.colorMode
              ]
            }
            color={theme.colorPalette.plainWhite}
            passedStyle={reviewApprovalStyles.addTaskIconButton}
          />
        )}
      </div>
    </>
  );
};

export default ReviewApprovalScreen;
