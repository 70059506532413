import React, { useContext, useState } from 'react';
import { activityHistoryContentStyles } from './activityHistoryContentStyles';
import { TaskHistoryEnum, TaskHistoryObj } from './TaskHistoryModal';
import { formatDateForDisplay } from '../../../utils/dateUtils';
import { DialogModalSwitchEnum } from '../DialogModalSwitch';
import confirmModalStore from '../../../stores/confirmModalStore';
import baseModalStore from '../../../stores/baseModalStore';
import { deleteTaskCommentById } from '../../../api/apiV1Task';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';
import { IconButton } from '../../Buttons/IconButton';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from '../../../assets/siteLineTheme';
import { AppContext } from '../../../context/AppContext';
import CommentCreateUpdate from '../../Accordion/CommentsAccordionContent/CommentCreateUpdate';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type ActivityHistoryContentProps = {
  task_id: string;
  activityHistory: TaskHistoryObj;
  updateParentState: Function;
};

const TaskHistoryContent: React.FC<ActivityHistoryContentProps> = (props) => {
  const { task_id, activityHistory, updateParentState } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const activityHistoryContentClasses = activityHistoryContentStyles({
    actionEnum: TaskHistoryEnum[activityHistory.action_type],
    colorMode: userPreference.colorMode,
  });
  const empName =
    activityHistory.given_name +
    ' ' +
    activityHistory.family_name.substring(0, 1);
  const formattedDateForDisplay = formatDateForDisplay(
    activityHistory.action_time,
    'History'
  );

  const { appState } = useContext(AppContext);
  const { userInfo } = appState;

  const [isEditTask, setIsEditTask] = useState<boolean>(false);
  const { setConfirmModal } = confirmModalStore(
    (state: { setConfirmModal: Function }) => ({
      setConfirmModal: state.setConfirmModal,
    })
  );

  const { setBaseModalContent, resetBaseModalContent } = baseModalStore(
    (state: {
      setBaseModalContent: Function;
      resetBaseModalContent: Function;
    }) => ({
      setBaseModalContent: state.setBaseModalContent,
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );

  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const deleteCommentModal = async (id: string) => {
    setBaseModalContent({
      isActive: true,
      width: windowDimensions.width > 420 ? 0.5 : 0.8,
      height: windowDimensions.width > 420 ? 0.1 : 0.2,
      dialogModalSwitchEnum: DialogModalSwitchEnum.ConfirmDeleteComment,
    });

    setConfirmModal({
      message: 'Are you sure you want to delete this message?',
      confirmButtonText: 'Delete',
      confirmOnClickAction: () => deleteComment(id),
    });
  };

  const deleteComment = async (id: string) => {
    await deleteTaskCommentById(id);
    sendAnalyticsEvent(analyticsEventConstants.DELETE_COMMENT);

    updateParentState();
    /* reset Modal */
    resetBaseModalContent();
  };

  const editComment = () => {
    /* istanbul ignore next */
    setIsEditTask(!isEditTask);
  };

  return (
    <>
      <div
        style={activityHistoryContentClasses.activityHistoryContentContainer}
      >
        <div
          style={activityHistoryContentClasses.activityHistorySvgTypeContent}
        >
          <div style={activityHistoryContentClasses.activityHistoryDate}>
            <div style={activityHistoryContentClasses.activityHistoryDateText}>
              {formattedDateForDisplay}
            </div>
            <div
              style={activityHistoryContentClasses.activityHistoryEmpNameText}
            >
              {empName}
            </div>
          </div>
          <div
            style={activityHistoryContentClasses.activityHistorySvgTypeContent}
          >
            <div style={activityHistoryContentClasses.activityHistorySvg}>
              <div
                style={activityHistoryContentClasses.activityHistorySvgLine}
              />
              <div
                style={activityHistoryContentClasses.activityHistorySvgCircle}
              />
            </div>

            {isEditTask &&
            TaskHistoryEnum[activityHistory.action_type] ===
              TaskHistoryEnum.ADD_COMMENT ? (
              <CommentCreateUpdate
                existComment={activityHistory.content}
                comment_id={activityHistory.id}
                task_id={task_id}
                updateParentState={updateParentState}
                setIsCreatingComment={setIsEditTask}
              />
            ) : (
              <div
                className={'column'}
                style={activityHistoryContentClasses.column}
              >
                <div
                  style={
                    TaskHistoryEnum[activityHistory.action_type] ===
                    TaskHistoryEnum.ADD_COMMENT
                      ? activityHistoryContentClasses.activityHistoryTypeContentComment
                      : activityHistoryContentClasses.activityHistoryTypeContent
                  }
                >
                  <div
                    className={'activityTypeText'}
                    style={activityHistoryContentClasses.activityTypeText}
                  >
                    {TaskHistoryEnum[activityHistory.action_type]}
                  </div>
                </div>
                <div
                  className={'activityHistoryContent'}
                  style={activityHistoryContentClasses.activityHistoryContent}
                >
                  {activityHistory.content && (
                    <div>{activityHistory.content}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* If action_type is ADD_COMMENT, then you can see the buttons  */}
        {!isEditTask &&
          TaskHistoryEnum[activityHistory.action_type] ===
            TaskHistoryEnum.ADD_COMMENT && (
            <div style={activityHistoryContentClasses.buttonContainer}>
              <div style={activityHistoryContentClasses.iconContainer}>
                {/* If you create your comment, you can see edit button */}
                {userInfo.empID === activityHistory.emp_id && (
                  <IconButton
                    label={'Edit button'}
                    icon={IconEnum.Edit}
                    onClick={editComment}
                    color={
                      theme.newColorPalette.buttons.editBlack[
                        userPreference.colorMode
                      ]
                    }
                    size={'1.3em'}
                  />
                )}
              </div>
              {/* If you create your comment, you can see delete button, or if you are higher level, you can see the delete button to delete others. */}
              {(userInfo.empID === activityHistory.emp_id ||
                !userInfo.SiteLineGroups.includes(
                  'siteline-storeassociate'
                )) && (
                <IconButton
                  label={'Delete button'}
                  icon={IconEnum.Delete}
                  onClick={() => deleteCommentModal(activityHistory.id)}
                  color={
                    theme.newColorPalette.snackbar.errorBlack[
                      userPreference.colorMode
                    ]
                  }
                  size={'1.4em'}
                  margin={'0 0 0 0.4em'}
                />
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default TaskHistoryContent;
