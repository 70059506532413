import React from 'react';
import { pdfNavigationContentStyles } from '../PDFNavigationBoxContent/pdfNavigationBoxContentStyles';
import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { IconContext } from 'react-icons';
import IconSelector from '../../Chip/IconSelector';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../../../stores/userPreferenceStore';
import {SafetyRiskObj} from "../../../dataWrappers/SafetyRiskComplianceDataWrapper";

export type PDFNavigationBoxContentProps = {
  contentData: NavigationObj | SafetyRiskObj |CalendarEvent | any;
  onClick: Function | undefined;
};

const PDFNavigationBoxContent: React.FC<PDFNavigationBoxContentProps> = (
  props
) => {
  const { contentData, onClick } = props;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const pdfNavigationBoxContentClasses = pdfNavigationContentStyles({
    colorMode: userPreference.colorMode,
    type: (contentData.pdf_file_information && contentData.pdf_file_information.type)
        || (contentData.file_information && contentData.file_information.type),
  });

  const clickHandler = (contentData: NavigationObj | SafetyRiskObj | CalendarEvent | any) => {
    if (onClick !== undefined) {
      onClick(contentData);
      if ((contentData.pdf_file_information && contentData.pdf_file_information.type === 'video/mp4')
      || (contentData.file_information && contentData.file_information.type === 'video/mp4')) {
        sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_VIDEO);
      } else {
        sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_PDF);
      }
    }
  };

  return (
    <div style={pdfNavigationBoxContentClasses.outlineBox}>
      <div
        aria-label={`PDF/Video of ${contentData.title}`}
        onClick={() =>
          onClick !== undefined && (contentData.pdf_file_information || contentData.file_information)
            ? clickHandler(contentData)
            : null
        }
        style={pdfNavigationBoxContentClasses.contentContainer}
      >
        <div style={pdfNavigationBoxContentClasses.pdfContainer}>
          <div style={{ width: '20%', margin: '0.2em' }}>
            {(contentData.pdf_file_information && contentData.pdf_file_information.type === 'video/mp4')
            || (contentData.file_information && contentData.file_information.type === 'video/mp4') ? (
              <IconContext.Provider value={pdfNavigationBoxContentClasses.icon}>
                {IconSelector(IconEnum.Video)}
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={pdfNavigationBoxContentClasses.icon}>
                {IconSelector(IconEnum.Pdf)}
              </IconContext.Provider>
            )}
          </div>
          <div style={pdfNavigationBoxContentClasses.titleText}>
            {decodeURIComponent(contentData.title)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFNavigationBoxContent;
