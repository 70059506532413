export type RouteInfo = {
    path: string,
    menuName: string,
    displayName?: string
}

export const RouteInfoList: RouteInfo[] =[
    {
        path: '/',
        menuName: 'Calendar',
    },
    {
        path: '/easy-print',
        menuName: 'EasyPrint',
    },
    {
        path: '/mobile-metrics',
        menuName: 'MobileMetrics',
    },
    {
        path: '/completion-data-dashboard',
        menuName: 'CompletionDataDashboard',
    },
    {
        path: '/task-approvals',
        menuName: 'taskApprovals',
    },
    {
        path: '/task-list-view',
        menuName: 'TaskListView',
    },
    {
        path: '/task-new-feature',
        menuName: 'TaskNewFeature',
    },
    {
        path: '/digital-merchandising',
        menuName: 'DigitalMerchandising',
    },
    {
        path: '/safety-risk-compliance',
        menuName: 'SafetyRiskCompliance',
    },
    {
        path: '/error',
        menuName: 'Error',
    },
    {
        path: '/user',
        menuName: 'User',
    }
]