import React, { Dispatch, SetStateAction } from 'react';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { IconButton } from '../Buttons/IconButton';
import { accordionHeaderStyles } from './accordionHeaderStyles';
import { AnimatedComponent } from '../animatedComponents/AnimatedComponent';
import { availableSpringParams } from '../animatedComponents/springStylesConstructor';
import { SpringTypeEnum } from '../animatedComponents/AnimatedComponentTypes';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../analytics/analytics';
import { AccordionContentEnum } from './Accordion';

export type AccordionTitleProps = {
  title: string;
  accordionContentEnum: AccordionContentEnum;
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const AccordionHeader: React.FC<AccordionTitleProps> = (props) => {
  const { title, accordionContentEnum, isCollapsed, setIsCollapsed } = props;
  const accordionHeaderClasses = accordionHeaderStyles();
  const handleExpandComment = () => {
    setIsCollapsed(!isCollapsed);
    if (
      !isCollapsed &&
      accordionContentEnum === AccordionContentEnum.Comments
    ) {
      sendAnalyticsEvent(analyticsEventConstants.VIEW_COMMENT); // Every time user expands comments section, we send analytics.
    }
  };
  const springsParam: Array<availableSpringParams> = [
    {
      springType: SpringTypeEnum.RotateSpring,
      activeRotateZ: 180,
      passiveRotateZ: 0,
    },
  ];

  return (
    <>
      <div style={accordionHeaderClasses.headerContainer}>
        <div>{title}</div>
        <AnimatedComponent
          isPassive={isCollapsed}
          springs={springsParam}
          label={'accordionHeaderAnimation'}
          loop={false}
        >
          <IconButton
            label={'expand comment button'}
            icon={IconEnum.DropDownArrow}
            onClick={handleExpandComment}
          />
        </AnimatedComponent>
      </div>
    </>
  );
};

export default AccordionHeader;
