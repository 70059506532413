import {getGroupName} from "../utils/common";

export type GAUserInfoObj = {
    storeNumber: string,
    territoryNumber: string,
    regionNumber: string,
    districtNumber: string,
    jobTitle: string,
    employeeId: string,
    sitelineGroup: string,
}

export const getUserInfoObj = () => {
    if (localStorage.getItem('okta-token-storage') && localStorage.getItem('okta-token-storage') !== '{}') {
        const oktaTokenStorage = JSON.parse(
            localStorage.getItem('okta-token-storage') as string
        );

        const claims = oktaTokenStorage?.idToken?.claims;
        if (claims === undefined) {
            return undefined
        } else {
            const highestGroup = getGroupName(claims.SiteLineGroups)

            const userInfoObj: GAUserInfoObj = {
                employeeId: claims.empID,
                jobTitle: claims.title,
                sitelineGroup: (highestGroup !== null) ? highestGroup : '',
                storeNumber: claims.locationnumber,
                districtNumber: claims.districtnumber,
                regionNumber: claims.regionnumber,
                territoryNumber: claims.territorynumber
            }
            return userInfoObj
        }
    }
    return undefined;
}