import {Box, Button, Divider, Drawer, Grid, IconButton, Paper, TextField, Typography,} from '@mui/material';
import {Cancel} from '@mui/icons-material';
import Autocomplete, {AutocompleteChangeReason,} from '@mui/material/Autocomplete';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/AppContext';
import TaskTypeFilterList from './TaskTypeFilterList';
import {analyticsEventConstants, sendAnalyticsEvent,} from '../../../analytics/analytics';
import {groups} from '../../../context/constants';
import {TaskStatus} from '../../../models/TaskStatus';
import {theme} from '../../../assets/siteLineTheme';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import drawerPropsStore, {defaultDrawerProps,} from '../../../stores/drawerPropsStore';
import userPreferenceStore, {UserPreferenceStoreState,} from 'src/stores/userPreferenceStore';
import useFetchUserGroup from '../../../hooks/fetchUserGroup';
import {createSavedFilter, deleteSavedFilter, SavedFilterObj,} from '../../../api/apiEmployee';
import baseModalStore from '../../../stores/baseModalStore';
import confirmModalStore from '../../../stores/confirmModalStore';
import {DialogModalSwitchEnum} from '../../Modal/DialogModalSwitch';
import {formattedFilterLocationList} from '../../../utils/formattedFilterLocationList';
import {AreaScopeEnum} from '../../../stores/taskCompletionDashboardStore';
import muiTheme from 'src/utils/mui/muiTheme';
import {BoxStretchDiv, DrawerHeaderContainerDiv, DrawerHeaderSectionDiv, RootDiv, TaskButtonsGridDiv} from "../../Forms/styles/divStyles";
import {BoxStyles} from "../../Forms/styles/boxStyles";
import {PaperStyles} from "../../Forms/styles/paperStyles";
import {FilterGridTypography} from "../../Forms/styles/typographyStyles";
import {ButtonStyles} from "../../Forms/styles/buttonStyles";
import {parseSavedFilter} from "../../../utils/savedFilter";
import {defaultTaskFiltersFilters} from "../../../utils/Data/defaultData";


const AutoCompleteStyling: React.FC = (props) => {
    return <Paper elevation={8} {...props} />;
};

export const formatStoreNumber = (store_number: string | number): string => {
    let storeNumber = store_number + '';
    if (storeNumber.length < 4 && storeNumber !== 'All') {
        while (storeNumber.length !== 4) {
            storeNumber = '0' + storeNumber;
        }
    }
    return storeNumber;
};

const FilterDrawer: React.FC = () => {
    const {appState} = useContext<any>(AppContext);
    const {userPermissions, storeLocationInformation, userInfo} = appState;
    const userOktaGroup = useFetchUserGroup(userInfo);
    const emp_id = userInfo.empID;

    // stores
    const {appliedFilters, setAppliedFilters} = appliedFiltersStore(
        (state: { appliedFilters: any; setAppliedFilters: any }) => ({
            appliedFilters: state.appliedFilters,
            setAppliedFilters: state.setAppliedFilters,
        })
    );
    const {drawerProps, setDrawerProps} = drawerPropsStore(
        (state: { drawerProps: any; setDrawerProps: any }) => ({
            drawerProps: state.drawerProps,
            setDrawerProps: state.setDrawerProps,
        })
    );
    const {userPreference} = userPreferenceStore(
        (state: { userPreference: UserPreferenceStoreState }) => ({
            userPreference: state.userPreference,
        })
    );

    const [selectedFilters, setSelectedFilters] = useState(JSON.parse(JSON.stringify(appliedFilters)));
    const [filteredStoreInformation, setFilteredStoreInformation] = useState(storeLocationInformation);

    useEffect(() => {
        getFilteredStoreInformation();
    }, [selectedFilters, storeLocationInformation]);

    const getFilteredStoreInformation = () => {
        let filteredStoreInformation = storeLocationInformation;

        if (filteredStoreInformation && selectedFilters.territory_number) {
            filteredStoreInformation = filteredStoreInformation.filter(
                (store: any) =>
                    store.territory_number === parseInt(selectedFilters.territory_number)
            );
        }

        if (filteredStoreInformation && selectedFilters.region_number) {
            filteredStoreInformation = filteredStoreInformation.filter(
                (store: any) =>
                    store.region_number === parseInt(selectedFilters.region_number)
            );
        }

        if (filteredStoreInformation && selectedFilters.district_number) {
            filteredStoreInformation = filteredStoreInformation.filter(
                (store: any) =>
                    store.district_number === parseInt(selectedFilters.district_number)
            );
        }

        if (filteredStoreInformation && selectedFilters.store_number) {
            filteredStoreInformation = filteredStoreInformation.filter(
                (store: any) =>
                    formatStoreNumber(store?.location_number?.toString()) ===
                    formatStoreNumber(selectedFilters?.store_number?.toString())
            );
        }

        setFilteredStoreInformation(filteredStoreInformation);
    };

    const setSelectedStoreNumber = (
        _event: ChangeEvent<{}>,
        value: string | null,
        _reason: AutocompleteChangeReason
    ) => {
        let valueSplit;
        let storeNumOnly;

        if (value) {
            valueSplit = value.split('-')[0].trim();
            storeNumOnly = formatStoreNumber(valueSplit);
        }

        if (storeNumOnly !== appliedFilters.store_number) {
            const newFiltersToApply = {
                ...selectedFilters,
                store_number: storeNumOnly,
            };
            setSelectedFilters(newFiltersToApply);
        }
    };

    const setSelectedDistrictNumber = (
        _event: ChangeEvent<{}>,
        value: string | null,
        _reason: AutocompleteChangeReason
    ) => {
        let districtNumber;
        if (value) {
            districtNumber = value.split('-')[0].trim();
        }

        if (districtNumber !== appliedFilters.district_number) {
            const newFiltersToApply = {
                ...selectedFilters,
                district_number: districtNumber,
            };
            setSelectedFilters(newFiltersToApply);
        }
    };

    const setSelectedRegionNumber = (
        _event: ChangeEvent<{}>,
        value: string | null,
        _reason: AutocompleteChangeReason
    ) => {
        let regionNumber;
        if (value) {
            regionNumber = value.split('-')[0].trim();
        }

        if (regionNumber !== appliedFilters.region_number) {
            const newFiltersToApply = {
                ...selectedFilters,
                region_number: regionNumber,
            };
            setSelectedFilters(newFiltersToApply);
        }
    };

    const setSelectedTerritoryNumber = (
        _event: ChangeEvent<{}>,
        value: string | null,
        _reason: AutocompleteChangeReason
    ) => {
        let territoryNumber;
        if (value) {
            territoryNumber = value.split('-')[0].trim();
        }

        if (territoryNumber !== appliedFilters.territory_number) {
            const newFiltersToApply = {
                ...selectedFilters,
                territory_number: territoryNumber,
            };
            setSelectedFilters(newFiltersToApply);
        }
    };

    const closeFilterDrawer = () => {
        setDrawerProps({...defaultDrawerProps});
    };

    const [isDeleteBtn, setIsDeleteBtn] = useState<boolean>(false);

    const saveFilter = async () => {
        sendAnalyticsEvent(analyticsEventConstants.SAVED_FILTERS);
        let localFilters = selectedFilters;
        if (userOktaGroup === groups.MICHAEL || userOktaGroup === groups.STANLEY) {
            if (localFilters.store_number !== userInfo.locationnumber) {
                window.alert(
                    'Cannot save filter because the store number is different with your location number ' +
                    userInfo.locationnumber
                );
                return;
            }
            localFilters = {
                ...localFilters,
                store_number: userInfo.locationnumber,
                task_status: TaskStatus.Approved,
            };
        }
        const newAppliedFilters = {
            ...appliedFilters,
            ...localFilters,
            isSavedFilter: true,
            filter_name: 'First Saved Filter',
        };
        setAppliedFilters(newAppliedFilters);

        const param: SavedFilterObj = {
            ...localFilters,
            filter_name: 'First Saved Filter',
            emp_id,
        };
        await createSavedFilter(param);
        setIsDeleteBtn(true);
        closeFilterDrawer();
    };

    const {setBaseModalContent, resetBaseModalContent} = baseModalStore(
        (state: {
            setBaseModalContent: Function;
            resetBaseModalContent: Function;
        }) => ({
            setBaseModalContent: state.setBaseModalContent,
            resetBaseModalContent: state.resetBaseModalContent,
        })
    );

    const {setConfirmModal} = confirmModalStore(
        (state: { setConfirmModal: Function }) => ({
            setConfirmModal: state.setConfirmModal,
        })
    );

    const windowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const deleteSavedFilterModal = async () => {
        if (isDeleteBtn) {
            setBaseModalContent({
                isActive: true,
                width: windowDimensions.width > 420 ? 0.5 : 0.8,
                height: windowDimensions.width > 420 ? 0.1 : 0.2,
                dialogModalSwitchEnum: DialogModalSwitchEnum.ConfirmDeleteComment,
            });

            setConfirmModal({
                message: 'Are you sure you want to delete your saved Filter?',
                confirmButtonText: 'Delete',
                confirmOnClickAction: () => deleteFilter(),
            });
        }
    };

    const deleteFilter = async () => {
        await deleteSavedFilter(emp_id);
        await parseSavedFilter(userInfo, userOktaGroup, setAppliedFilters, defaultTaskFiltersFilters)

        setIsDeleteBtn(false);
        resetBaseModalContent();
        closeFilterDrawer()
    };

    useEffect(() => {
        if (appliedFilters.filter_name !== undefined && appliedFilters.filter_name !== null) {
            setIsDeleteBtn(true);
        }
    }, [drawerProps.isDrawerOpen]);

    return (
        <Drawer
            anchor="right"
            open={drawerProps.isDrawerOpen}
            onClose={closeFilterDrawer}
            PaperProps={{
                sx: {
                    width: '32%',
                    [muiTheme.breakpoints.between('xs', 'sm')]: {
                        width: '100%',
                    },
                    minWidth: '320px',
                    overflow: 'hidden'
                }
            }}
        >
            <RootDiv colorMode={userPreference.colorMode}>
                <Box sx={BoxStyles(userPreference.colorMode).drawerBackgroundBox}/>
                <DrawerHeaderContainerDiv>
                    <IconButton
                        aria-label="Close Filter Drawer Icon"
                        onClick={closeFilterDrawer}
                        sx={{color: theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]}}
                    >
                        <Cancel/>
                    </IconButton>
                    <DrawerHeaderSectionDiv>
                        <Paper style={PaperStyles(userPreference.colorMode).headerPaper} elevation={4}>
                            <Typography sx={{fontSize: '1em', fontWeight: 'bold',}}>
                                Filters
                            </Typography>
                        </Paper>
                    </DrawerHeaderSectionDiv>
                </DrawerHeaderContainerDiv>
                <BoxStretchDiv headerComponentHeight={'40'}>
                    <Box sx={BoxStyles(userPreference.colorMode).filterSearchBox}>
                        <Box sx={BoxStyles(userPreference.colorMode).filterDrawerContentContainer}>
                            {userPermissions?.filterByLocation ? (
                                <Paper style={PaperStyles(userPreference.colorMode).filterSectionContainer}
                                       elevation={4}>
                                    <Typography
                                        sx={{color: theme.colorPalette.drawerHeaderText,}}>
                                        Location
                                    </Typography>
                                    <Divider sx={{marginBottom: '1rem'}}/>
                                    <Autocomplete
                                        aria-label="territory-filter-selection"
                                        options={formattedFilterLocationList(
                                            AreaScopeEnum.Territory,
                                            filteredStoreInformation
                                        )}
                                        getOptionLabel={(option) => option || ''}
                                        renderInput={(params) => (
                                            <TextField{...params} label="Select Territory Number" variant="outlined"/>
                                        )}
                                        onChange={setSelectedTerritoryNumber}
                                        defaultValue={
                                            appliedFilters &&
                                            appliedFilters.territory_number &&
                                            appliedFilters.territory_number
                                        }
                                        PaperComponent={AutoCompleteStyling}
                                    />
                                    <Divider style={{marginBottom: '1rem'}}/>
                                    <Autocomplete
                                        aria-label="region-filter-selection"
                                        options={formattedFilterLocationList(
                                            AreaScopeEnum.Region,
                                            filteredStoreInformation
                                        )}
                                        getOptionLabel={(option) => option || ''}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Region Number" variant="outlined"/>
                                        )}
                                        onChange={setSelectedRegionNumber}
                                        defaultValue={
                                            appliedFilters &&
                                            appliedFilters.region_number &&
                                            appliedFilters.region_number
                                        }
                                        PaperComponent={AutoCompleteStyling}
                                    />
                                    <Divider style={{marginBottom: '1rem'}}/>
                                    <Autocomplete
                                        aria-label="district-filter-selection"
                                        options={formattedFilterLocationList(
                                            AreaScopeEnum.District,
                                            filteredStoreInformation
                                        )}
                                        getOptionLabel={(option) => option || ''}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select District Number" variant="outlined"/>
                                        )}
                                        onChange={setSelectedDistrictNumber}
                                        defaultValue={
                                            appliedFilters &&
                                            appliedFilters.district_number &&
                                            appliedFilters.district_number
                                        }
                                        PaperComponent={AutoCompleteStyling}
                                    />
                                    <Divider style={{marginBottom: '1rem'}}/>
                                    <Autocomplete
                                        aria-label="Select store number from dropdown"
                                        options={formattedFilterLocationList(
                                            AreaScopeEnum.Store,
                                            filteredStoreInformation
                                        )}
                                        getOptionLabel={(option) => option || ''}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Store Number" variant="outlined"/>
                                        )}
                                        onChange={setSelectedStoreNumber}
                                        defaultValue={
                                            appliedFilters &&
                                            appliedFilters.store_number &&
                                            appliedFilters.store_number
                                        }
                                        PaperComponent={AutoCompleteStyling}
                                    />
                                </Paper>
                            ) : (
                                <></>
                            )}
                            <Paper sx={PaperStyles(userPreference.colorMode).basicPaper} elevation={4}>
                                <TaskTypeFilterList
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={setSelectedFilters}
                                />
                            </Paper>
                        </Box>
                        <TaskButtonsGridDiv>
                            <FilterGridTypography component={Grid} item xs={12} align={'right'}>
                                <Button
                                    onClick={saveFilter}
                                    sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).saveApplyFiltersButton}}
                                    fullWidth
                                >
                                    SAVE & APPLY FILTERS
                                </Button>
                                <Button
                                    onClick={deleteSavedFilterModal}
                                    sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).editButton}}
                                    fullWidth
                                >
                                    DELETE FILTER
                                </Button>
                            </FilterGridTypography>
                        </TaskButtonsGridDiv>
                    </Box>
                </BoxStretchDiv>
            </RootDiv>
        </Drawer>
    );
};

export default FilterDrawer;
