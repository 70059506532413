import dateSelectionForDisplayStore, {
  dateLabelGenerator,
  DateRangeTypeEnum,
} from '../../../stores/dateSelectionForDisplayStore';
import { addDays, subDays } from 'date-fns';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import { removeTime } from '../../../utils/dateUtils';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../analytics/analytics';
import { DirectionEnum } from 'src/utils/enum/DirectionEnum';

export const timeDirectionArrowClickHandler = (direction: DirectionEnum) => {
  const appliedFilters = appliedFiltersStore.getState().appliedFilters;
  const setAppliedFilters = appliedFiltersStore.getState().setAppliedFilters;

  const setDateSelection =
    dateSelectionForDisplayStore.getState().setDateSelectionForDisplayProps;
  const getDateSelection =
    dateSelectionForDisplayStore.getState().dateSelectionForDisplayProps;
  if (direction === DirectionEnum.Back) {
    sendAnalyticsEvent(analyticsEventConstants.CALENDAR_BACK);
    if (getDateSelection.dateRangeType === DateRangeTypeEnum.DAY) {
      setDateSelection({
        dateRangeType: DateRangeTypeEnum.DAY,
        selectedDisplayDate: subDays(getDateSelection.selectedDisplayDate, 1),
        dateLabel: dateLabelGenerator({
          dateRangeTypeEnum: DateRangeTypeEnum.DAY,
          selectedDisplayDate: subDays(getDateSelection.selectedDisplayDate, 1),
        }),
      });
      setAppliedFilters({
        ...appliedFilters,
        current_date: removeTime(subDays(getDateSelection.selectedDisplayDate, 1)).toISOString(),
        view_type: DateRangeTypeEnum.DAY.toLowerCase(),
      });
    }
    if (getDateSelection.dateRangeType === DateRangeTypeEnum.WEEK) {
      setDateSelection({
        dateRangeType: DateRangeTypeEnum.WEEK,
        selectedDisplayDate: subDays(getDateSelection.selectedDisplayDate, 7),
        dateLabel: dateLabelGenerator({
          dateRangeTypeEnum: DateRangeTypeEnum.WEEK,
          selectedDisplayDate: subDays(getDateSelection.selectedDisplayDate, 7),
        }),
      });
      setAppliedFilters({
        ...appliedFilters,
        current_date: removeTime(subDays(getDateSelection.selectedDisplayDate, 7)).toISOString(),
        view_type: DateRangeTypeEnum.WEEK.toLowerCase(),
      });
    }
  } else {
    sendAnalyticsEvent(analyticsEventConstants.CALENDAR_FORWARD);
    if (getDateSelection.dateRangeType === DateRangeTypeEnum.DAY) {
      setDateSelection({
        dateRangeType: DateRangeTypeEnum.DAY,
        selectedDisplayDate: addDays(getDateSelection.selectedDisplayDate, 1),
        dateLabel: dateLabelGenerator({
          dateRangeTypeEnum: DateRangeTypeEnum.DAY,
          selectedDisplayDate: addDays(getDateSelection.selectedDisplayDate, 1),
        }),
      });
      setAppliedFilters({
        ...appliedFilters,
        current_date: removeTime(addDays(getDateSelection.selectedDisplayDate, 1)).toISOString(),
        view_type: DateRangeTypeEnum.DAY.toLowerCase(),
      });
    }
    if (getDateSelection.dateRangeType === DateRangeTypeEnum.WEEK) {
      setDateSelection({
        dateRangeType: DateRangeTypeEnum.WEEK,
        selectedDisplayDate: addDays(getDateSelection.selectedDisplayDate, 7),
        dateLabel: dateLabelGenerator({
          dateRangeTypeEnum: DateRangeTypeEnum.WEEK,
          selectedDisplayDate: addDays(getDateSelection.selectedDisplayDate, 7),
        }),
      });
      setAppliedFilters({
        ...appliedFilters,
        current_date: removeTime(addDays(getDateSelection.selectedDisplayDate, 7)).toISOString(),
        view_type: DateRangeTypeEnum.WEEK.toLowerCase(),
      });
    }
  }
};
