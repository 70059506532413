import { useQuery } from '@tanstack/react-query';
import { getTaskAndPromotionsWithAppliedFilters } from '../api/apiV1Task';

export const useFetchTaskAndPromotionsWithAppliedFilters = (
  queryString: string,
  triggerTime: Date,
  isFiltersReady: boolean
) => {
  const { data, error, isLoading } = useQuery(
    ['taskandPromotions', queryString, triggerTime.getTime()],
    () => getTaskAndPromotionsWithAppliedFilters(queryString),
      {
        enabled: isFiltersReady,
      }
  );
  return { data, error, isLoading };
};
