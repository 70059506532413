import axios, { AxiosResponse } from 'axios';
import { env } from '../envConfig';
import { apiRequestConfig } from './apiV1Task';
import {getRequest} from './apiUtils';
import { ColorModeTypeEnum, ViewTypeEnum } from '../stores/userPreferenceStore';
import snackbarPropsStore from '../stores/snackbarPropsStore';

const setSnackbarProps = snackbarPropsStore.getState().setSnackbarProps;

export const createOrUpdateEmployeeInfo = async (): Promise<object | null> => {
  try {
    const response: AxiosResponse = await axios.put(
      `${env.REACT_APP_BACKEND_URL}/api/v1/employees`,
      {},
      await apiRequestConfig()
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export type EmployeePreferenceObj = {
  color_mode_type: ColorModeTypeEnum;
  view_type: ViewTypeEnum;
  view_row_number: number;
};

export const createOrUpdateEmployeePreference = async (
  values: EmployeePreferenceObj
) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${env.REACT_APP_BACKEND_URL}/api/v1/employees/employeePreference`,
      { ...values },
      await apiRequestConfig()
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getEmployeePreference = async () => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/employees/employeePreference`;
    return getRequest(url);
  } catch (error) {
    return undefined;
  }
};

// todo - refactor "number | string | undefined"
export type SavedFilterObj = {
  emp_id: string;
  territory_number?: number | string | undefined;
  district_number?: number | string | undefined;
  store_number?: number |  string | undefined;
  region_number?: number |  string | undefined
  isSavedFilter?: boolean | undefined;
  filter_name?: string | undefined;
  task_types?: {
    Sephora?: boolean;
    Merchandising?: boolean;
    'Pricing & Signing'?: boolean;
    Visual?: boolean;
    Operations?: boolean;
    'Loss Prevention'?: boolean;
    'Training & Recognition'?: boolean;
    'Marketing Promotions'?: boolean;
    Event?: boolean;
    Holiday?: boolean;
  };
};
export const createSavedFilter = async (values: SavedFilterObj) => {
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {
    const response: AxiosResponse = await axios.put(
      `${env.REACT_APP_BACKEND_URL}/api/v1/employees/savedFilter`,
      { ...values },
      await apiRequestConfig()
    );
    setSnackbarProps({
      actionStatus: 'success',
      isSnackbarVisible: true,
      correlationId: undefined,
    });
    return response.data;
  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};

export const getSavedFilter = async () => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/employees/savedFilter`;
    return getRequest(url);
  } catch (error) {
    return undefined;
  }
};

export const deleteSavedFilter = async (emp_id: string) => {
  setSnackbarProps({
    actionStatus: 'loading',
    isSnackbarVisible: true,
    correlationId: undefined,
  });
  try {
    const response: AxiosResponse = await axios.delete(
      `${env.REACT_APP_BACKEND_URL}/api/v1/employees/savedFilter`,
      await apiRequestConfig()
    );
    setSnackbarProps({
      actionStatus: 'success',
      isSnackbarVisible: true,
      correlationId: undefined,
    });
    return response.data;
  } catch (error) {
    setSnackbarProps({
      actionStatus: 'error',
      isSnackbarVisible: true,
      // @ts-ignore
      correlationId: error.config.headers['Correlation-ID'],
    });
    return null;
  }
};
