import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {sendAnalyticsPageview} from "./analytics";
import {getUserInfoObj} from "../okta/getUserInfoObj";
import {RouteInfoList} from "./routeInfo";


const PageTracker = () => {
    const location = useLocation();
    const pageViewTracked = useRef<string | null>(null);

    useEffect(() => {
        const isLogin = getUserInfoObj() !== undefined;

        if (isLogin && pageViewTracked.current !== location.pathname) {

            const menuPath = location.pathname;
            // const menuName = location.pathname === '/' ? 'Calendar' : RouteInfoList.find((menu) => menu.path === menuPath)?.menuName
            const menuName = RouteInfoList.find((menu) => menu.path === menuPath)?.menuName

            if(menuName) {
                sendAnalyticsPageview(menuPath, menuName)
                pageViewTracked.current = location.pathname;
            }
        }
    }, [location.pathname]);

    return null;
};

export default PageTracker;
