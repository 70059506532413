import {OktaAuth} from "@okta/okta-auth-js";
import { env } from "../envConfig";

const OKTA_ISSUER = env.REACT_APP_OKTA_ISSUER;
const CLIENT_ID = env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = '/implicit/callback';

const ISSUER = `${OKTA_ISSUER}`;
const REDIRECT_URI = `${window.location.origin}${CALLBACK_PATH}`;
const LOGOUT_REDIRECT_URI = `${window.location.origin}`;

const SCOPES = [
    'openid',
    'email',
    'profile', "device_sso", "offline_access"
];
export const config = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
    scopes: SCOPES,
    pkce: true,
    // onAuthRequired: onAuthRequired()
};

export const getOktaAuth = () => {
    return new OktaAuth(config)
};
