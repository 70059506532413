import {eventItems} from '../../Forms/TaskUtils';
import {Divider, FormControl, FormControlLabel, FormGroup, Typography,} from '@mui/material';
import CheckBox from '@mui/material/Checkbox';
import React from 'react';
import Chip from '../../Chip/Chip';
import {ChipVariantEnum} from '../../Chip/chipUtils/ChipEnums';
import {chipBackgroundColorPicker, chipTextColorPicker,} from '../../Chip/chipUtils/chipColorPickers';
import userPreferenceStore, {UserPreferenceStoreState,} from 'src/stores/userPreferenceStore';

export type TaskTypeFilterListProps = {
    selectedFilters: {
        store_number: number;
        task_types: object;
    };
    setSelectedFilters: Function;
};

const TaskTypeFilterList: React.FC<TaskTypeFilterListProps> = (props) => {
    const {selectedFilters, setSelectedFilters} = props;
    const filteredTaskTypes = selectedFilters.task_types;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        filteredTaskTypes[event.target.name] = !filteredTaskTypes[event.target.name];
        setSelectedFilters({...selectedFilters, task_types: filteredTaskTypes});
    };

    const {userPreference} = userPreferenceStore(
        (state: { userPreference: UserPreferenceStoreState }) => ({
            userPreference: state.userPreference,
        })
    );

    return (
        <>
            <Typography>Task Types</Typography>
            <Divider/>
            <FormControl component={'fieldset'}>
                <FormGroup>
                    {eventItems.map((task_type, index) => {
                        return (
                            <div key={task_type.label}>
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            id={`${task_type.label}-filter-checkbox`}
                                            name={task_type.label}
                                            color={'primary'}
                                            onChange={handleChange}
                                            checked={filteredTaskTypes && filteredTaskTypes[task_type.label]}
                                        />
                                    }
                                    label={
                                        <Chip
                                            variant={ChipVariantEnum.Filled}
                                            chipTextColor={chipTextColorPicker(userPreference.colorMode, task_type.label, undefined)}
                                            chipBackgroundColor={chipBackgroundColorPicker(userPreference.colorMode, task_type.label, undefined)}
                                        >
                                            {task_type.label}
                                        </Chip>
                                    }
                                />
                            </div>
                        );
                    })}
                </FormGroup>
            </FormControl>
        </>
    );
};

export default TaskTypeFilterList;
