import {create, SetState} from 'zustand';
import {AppliedFiltersProps} from './models/AppliedFiltersProps';
import {TaskStoreCompletionStatus} from '../models/TaskStoreCompletionStatus';
import {removeTime} from '../utils/dateUtils';

const appliedFilters: AppliedFiltersProps = {
  appliedFilters: {
    task_types: {
      Event: false,
      'Loss Prevention': false,
      'Marketing Promotions': false,
      Merchandising: false,
      Operations: false,
      'Pricing & Signing': false,
      Sephora: false,
      'Training & Recognition': false,
      Visual: false,
      Holiday: false,
      'Risk & Compliance': false,
      'Special Projects': false,
      Surveys: false,
    },
    task_status: undefined,
    completion_status: TaskStoreCompletionStatus.Incomplete,
    store_number: undefined,
    district_number: undefined,
    region_number: undefined,
    territory_number: undefined,
    min_selected_date: undefined, // new Date(startOfWeek(Date.now())),
    max_selected_date: undefined, // new Date(endOfWeek(Date.now())),
    current_date: removeTime(new Date()).toISOString(),
    view_type: 'week',
  },
};

const appliedFiltersStore = create((set: SetState<any>) => ({
  ...appliedFilters,
  setAppliedFilters: (appliedFilters: any) => {
    set(() => ({ appliedFilters }));
  },
}));

export default appliedFiltersStore;